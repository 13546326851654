import React, { Component, useEffect, useState } from "react";
import { AiFillCalculator } from "react-icons/ai";
import { RiCalculatorFill, RiDeleteBin5Line, RiDeleteBin6Line } from "react-icons/ri";
import { VscHistory } from "react-icons/vsc"
import { Container, Row, Col } from 'reactstrap';

import { Button, CustomInput } from "reactstrap";
import { numberWithCommas } from "../utils/customFunction";
import { IoMdClose } from "react-icons/io";
import { FaCalculator } from "react-icons/fa";

const FixedPlugin = (props) => {

  const [calculatorFlag, setCalculatorFlag] = useState(false);

  let [input, setInput] = useState('0');
  let [input1, setInput1] = useState(0);
  let [percent, setPercent] = useState(0);
  let [output, setOutput] = useState('');
  let [keyPress, setKeyPress] = useState('');
  const [history, setHistory] = useState(false);
  const [calculationHistory, setCalculationHistory] = useState<any>([]);
  const [input1Focused, setIsInput1Focused] = useState(false);
  const inputRef: any = React.useRef(null);

  useEffect(() => {
    if (input1 >= 1) handleEqual();
  }, [input1])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown1);
    return () => {
      document.removeEventListener("keydown", handleKeyDown1);
    };
  }, []);

  const handleKeyDown1 = (event) => {
    if (event.keyCode === 27) { // 27 is the key code for "Esc"
      setInput("0");
      setOutput("");
    }
    if ((event.keyCode === 13 && event.key === 'Enter') || (event.keyCode === 61 && event.key === '=')) {
      setInput1(1);
      // handleEqual()
    }
    // if (event.key === 'Delete') {
    //   handleClear();
    // }

  };

  const handleEqual = () => {
    setInput1(0)
    if (input !== '') {
      if (input !== '0') {
        try {

          let result = eval(input.replaceAll('%', '/100')).toString();
          setOutput(result);
          setCalculationHistory((prevHistory) => [...prevHistory, `${input.toString()} = ${result.includes('.') ? numberWithCommas(parseFloat(result || '').toFixed(4)) : result}`]);
          setInput('0')

        } catch (error) {
          setOutput('Error');
        }
      }
    }
  };

  const checkOperator = (expression) => {
    const operators = ["*-", "/-"];
    for (const operator of operators) {
      if (expression.includes(operator)) {
        const index = expression.indexOf(operator);
        if (index + 2 < expression.length && /[+\-*/]/.test(expression[index + 2])) {
          return false;
        }
      }
    }
    return true;
  };
  // const checkDoubleOperator = (expression) => {
  //   const operators = ["+", "-", "*", "/"];
  //   for (let i = 0; i < expression.length - 1; i++) {
  //     const currentChar = expression[i];
  //     const nextChar = expression[i + 1];
  //     if (operators.includes(currentChar) && operators.includes(nextChar)) {
  //       return false;
  //     }
  //   }
  //   return true;
  // };
  const checkDoubleOperator = (expression) => {
    const operators = ["+", "-", "*", "/"];
    for (let i = 0; i < expression.length - 1; i++) {
      const currentChar = expression[i];
      const nextChar = expression[i + 1];
      if (operators.includes(currentChar) && currentChar === nextChar) {
        return false;
      }
    }
    return true;
  };

  function checkDecimalPoints(data) {
    for (let i = 0; i < data.length; i++) {
      const decimalCount = data[i].split('.').length - 1;
      if (decimalCount > 1) {
        return false;
      }
    }
    return true;
  }

  const handleInputChange = async (event) => {
    const { value, selectionStart, selectionEnd } = event.target;
    let valueBeforeCursor = value.substring(0, selectionStart);
    let valueAfterCursor = value.substring(selectionStart);
    let inputValue = event.target.value === '' ? '0' : event.target.value;
    // (inputRef.current.selectionStart === 1 && (inputValue === '*' || inputValue === '/'))
    // Validate the input
    if ((inputRef.current.selectionStart === 1 && inputValue === '0' && input !== '0.')
      || event.target.value.charAt(0) === "*" || (event.target.value.charAt(0) === "0" && event.target.value.charAt(1) === "*")
      || event.target.value.charAt(0) === "%" || (event.target.value.charAt(0) === "0" && event.target.value.charAt(1) === "%")
      || event.target.value.charAt(0) === "/" || (event.target.value.charAt(0) === "0" && event.target.value.charAt(1) === "/")) {
      // Do not update the input value
      return;
    } else {
      let pointVal = ''
      const operators = ['*', '/', '-', '+', '%'];
      let valueAfterLastOperator = '';
      let expression = valueBeforeCursor;
      let regixoperators = /[*/+\-%]/g;
      let matches = expression.match(regixoperators);
      let lastOperator = matches ? matches.pop() : null;
      let valueBeforeFirstOperator = ''
      // for (let i = operators.length - 1; i >= 0; i--) {
      // const operator = operators[i];
      if (!expression.includes(lastOperator)) {
        valueBeforeFirstOperator = expression;
      } else {
        valueBeforeFirstOperator = '';
      }
      const parts = expression.split(lastOperator);

      if (parts.length > 1) {
        valueAfterLastOperator = parts[parts.length - 1];
        // break;
      }
      const regex = /[1-9]/;

      if (regex.test(valueAfterLastOperator?.charAt(1)) && valueAfterLastOperator.charAt(0) === '0') {

        valueAfterLastOperator = valueAfterLastOperator.replace(valueAfterLastOperator.charAt(0), '');
        valueBeforeCursor = valueBeforeCursor.slice(0, -1);
        valueBeforeCursor = valueBeforeCursor.slice(0, -1);
        const modifiedValue = valueBeforeCursor + valueAfterLastOperator + valueAfterCursor;
        // Set the modified value in the input element
        inputRef.current.value = modifiedValue;
        inputValue = modifiedValue;
      }

      if (valueAfterLastOperator.charAt(0) === '0' && valueAfterLastOperator?.charAt(1) !== '.' && regex.test(valueAfterCursor?.charAt(0))) {
        valueAfterLastOperator = valueAfterLastOperator.replace(valueAfterLastOperator.charAt(0), '');
        valueBeforeCursor = valueBeforeCursor.slice(0, -1);
        const modifiedValue = valueBeforeCursor + valueAfterLastOperator + valueAfterCursor;
        // Set the modified value in the input element
        inputRef.current.value = modifiedValue;
        inputValue = modifiedValue;
        // Calculate the new cursor position
        const newCursorPosition = (valueBeforeCursor.length - 1) + 1;

        // Set the selection range for the input element
        inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);

        // Focus the input
        inputRef.current.focus();
      }
      // }
      // if (valueAfterLastOperator === '.') {
      //   inputValue = inputValue.slice(0, -1) + '0.'

      // }
      if (valueAfterLastOperator === '.') {
        const modifiedValue = valueBeforeCursor.slice(0, -1) + '0.' + valueAfterCursor;

        // Set the modified value in the input element
        inputRef.current.value = modifiedValue;
        inputValue = modifiedValue;

        // Calculate the new cursor position
        const newCursorPosition = (valueBeforeCursor.length - 1) + '0.'.length;

        // Set the selection range for the input element
        inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);

        // Focus the input
        inputRef.current.focus();
      }
      let flag = checkDecimalPoints(inputValue.split(/[\+\-\*\/%]/g).filter(operator => operator !== ''));
      if (event.nativeEvent.inputType === 'deleteContentBackward' || event.nativeEvent.inputType === 'deleteContentForward') {
        // setInput(inputValue === '' ? '0' : (inputValue === '.' ? '0' + inputValue : inputValue));
        setInput(inputValue === '' ? '0' : inputValue);
        try {
          let result = await eval(inputValue.replaceAll('%', '/100'));
          setOutput(result);
        } catch (e) {
          // If the input is invalid but not empty, keep the current output
          if (inputValue !== "") {
            return;
          }
          setOutput("");
        }
      } else {
        const isValidExpression =
          // /^([0-9]|([+\-*.\/%]))+$/.test(inputValue) &&
          flag &&
          /^[0-9+\-*.\/%]+(\.[0-9]+)?$/.test(inputValue) &&
          (valueBeforeFirstOperator.match(/\./g) || []).length <= 1 &&
          (valueAfterLastOperator.match(/\./g) || []).length <= 1 &&
          !valueAfterLastOperator.startsWith('00') &&
          !inputValue.endsWith('%%') &&
          // !/(\+[\-]|[\-]\+)/.test(inputValue) &&
          !/(\+[\-]|[\-]\+|\-[*/])/.test(inputValue) &&
          // !/([+\-*.\/]){2,}/.test(inputValue)   // Disallow consecutive operators
          !/([+\/*]{2,}|(?<=\d)-{2,})/.test(inputValue) && checkOperator(inputValue) && checkDoubleOperator(inputValue)  // Disallow consecutive operators

        if (isValidExpression) {
          if (inputValue.charAt(0) === '0' && inputValue.charAt(1) != '.') {
            // Remove the leading zero
            if (inputValue.charAt(0))
              inputValue = inputValue.slice(1);
          }
          setInput(inputValue === '' ? '0' : inputValue === '.' ? '0' + inputValue : inputValue);

          let operatorAtLast = ['+', '-', '*', '/'].includes(inputValue.charAt(inputValue.length - 1));
          inputValue = operatorAtLast ? inputValue.slice(0, -1) : inputValue;
          if ((['+', '-', '*', '/'].includes(inputValue.charAt(inputValue.length - 1)) === false)) {
            try {
              let result = await eval(inputValue === '' ? '0' : inputValue.replaceAll('%', '/100')).toString();
              setOutput(result);
            } catch (e) {
              // If the input is invalid but not empty, keep the current output
              setOutput("Error");
              if (inputValue !== "") {
                return;
              }

            }
          }
        } else {
          if (selectionStart > 0 && selectionStart < value.length) {
            setInput((prevValue) => {
              const newCursorPosition = selectionStart + 1;
              // Move the cursor to the desired position
              setTimeout(() => {
                event.target.setSelectionRange(newCursorPosition - 2, newCursorPosition - 2);
              }, 0.0001);
              prevValue = prevValue.slice(0, selectionStart) + prevValue.slice(selectionStart)
              return prevValue;
            });
          }
        }
      }
    }
  };

  const handleInput1 = async (value) => {
    if (value === '0' && (input === '0' || input.length === 0)) {
      // Do not update the input value
      return;
    } else {

      // if (isValidExpression) {
      const inputEl: any = inputRef.current;
      const startPos = inputEl.selectionStart;
      const endPos = inputEl.selectionEnd;
      let currentValue = inputEl.value;
      let inputData = currentValue.slice(0, startPos) + value + currentValue.slice(endPos)
      const operators = ['*', '/', '-', '+', '%'];
      let valueAfterLastOperator = '';
      let expression = currentValue.slice(0, startPos) + value;
      let regixoperators = /[*/+\-%]/g;
      let matches = expression.match(regixoperators);
      let lastOperator = matches ? matches.pop() : null;
      let valueBeforeFirstOperator = ''
      // for (let i = operators.length - 1; i >= 0; i--) {
      // const operator = operators[i];

      if (!expression.includes(lastOperator)) {
        valueBeforeFirstOperator = expression;
      } else {
        valueBeforeFirstOperator = '';
      }
      const parts = expression.split(lastOperator);
      if (parts.length > 1) {
        valueAfterLastOperator = parts[parts.length - 1];
        // break;
      }
      // }
      if (valueAfterLastOperator === '.') {
        value = '0.'
        inputData = currentValue.slice(0, startPos) + value + currentValue.slice(endPos)
      }

      const regex = /[1-9]/;

      if (regex.test(valueAfterLastOperator?.charAt(1)) && valueAfterLastOperator.charAt(0) === '0') {
        currentValue = currentValue.slice(0, startPos)
        currentValue = currentValue.slice(0, -1)
        inputData = currentValue + value + currentValue.slice(endPos)
      }
      if (valueAfterLastOperator.charAt(0) === '0' && valueAfterLastOperator?.charAt(1) !== '.' && regex.test(currentValue.slice(endPos)?.charAt(0))) {
        value = ''
        inputEl.focus();
        return

      }
      let flag1 = checkDecimalPoints(inputData.split(/[\+\-\*\/%]/g).filter(operator => operator !== ''))
      const isValidExpression =
        flag1 &&
        /^[0-9+\-*.\/%]+(\.[0-9]+)?$/.test(currentValue.slice(0, startPos) + value) &&
        !(currentValue.slice(0, startPos) + value).startsWith('0*') &&
        !(currentValue.slice(0, startPos) + value).startsWith('*') &&
        !(currentValue.slice(0, startPos) + value).startsWith('0/') &&
        !(currentValue.slice(0, startPos) + value).startsWith('/') &&
        (valueBeforeFirstOperator.match(/\./g) || []).length <= 1 &&
        (valueAfterLastOperator.match(/\./g) || []).length <= 1 &&
        !valueAfterLastOperator.startsWith('00') &&
        !(currentValue.slice(0, startPos) + value).endsWith('%%') &&
        !/([+\/*]{2,}|(?<=\d)-{2,})/.test(inputData) &&
        // !/([+.\/*]{2,}|(?<=\d)-{2,})/.test(currentValue.slice(0, startPos) + value) && 
        !/(\+[\-]|[\-]\+|\-[*/])/.test(inputData)

      let flag = false;
      if (currentValue.slice(endPos).length) {
        flag = true
      }
      if (isValidExpression && checkOperator((currentValue).slice(0, startPos) + value) === true && checkDoubleOperator((currentValue).slice(0, startPos) + value) === true) {

        let outputVal = (currentValue).slice(0, startPos) + value + currentValue.slice(endPos);

        setInput((prevInput) => (outputVal.charAt(0) === '0' && outputVal.charAt(1)) == '.' ? outputVal : (outputVal.charAt(0) === '0' ? outputVal.substring(1) : outputVal));

        if (flag || (['+', '-', '*', '/'].includes(value) === false)) {
          if (keyPress !== 'Enter') {
            // Update the input value and reset selection
            inputData = (inputData.charAt(0) === '0' && inputData.charAt(1) !== '.') ? inputData.slice(1) : inputData;
            inputEl.value = inputData;
            // set cursor position after the inserted text
            const newCursorPos = startPos + value.length;
            inputEl.setSelectionRange(newCursorPos, newCursorPos);
            // focus the input
            inputEl.focus();
            let operatorAtLast = ['+', '-', '*', '/'].includes(inputData.charAt(inputData.length - 1));
            inputData = operatorAtLast ? inputData.slice(0, -1) : inputData
            try {
              inputData = inputData === '' ? '0' : inputData;
              let result = await eval((['+', '-', '*', '/', '%'].includes(inputData.charAt(inputData.length - 2)) && inputData.charAt(inputData.length - 1) === '.') ? inputData.replace(inputData.charAt(inputData.length - 1), '0.').replaceAll('%', '/100') : inputData.replaceAll('%', '/100')).toString();
              setOutput(result);
            } catch (error) {
              if (input === '0') {
                setOutput('0');
              } else {
                setOutput('Error');
              }
            }
          }
        } else {
          inputEl.focus();
        }
      }
      // }
    }
  };

  const [percentCount, setPercentCount] = useState(0);
  useEffect(() => {
    const percentFuntion = () => {
      setPercentCount(0);
      let value = '%';
      const inputEl: any = inputRef.current;
      const startPos = inputEl.selectionStart;
      const endPos = inputEl.selectionEnd;
      let currentValue = inputEl.value;
      let inputData = currentValue.slice(0, startPos) + value + currentValue.slice(endPos)
      inputEl.value = inputData;
      if (inputData.charAt(0) === '%' || inputData === '%' || inputData === '0%') {
        // setInput('0');
        return
      } else if ((currentValue.slice(0, startPos) + value).endsWith('%%')) {
        return
      } else {
        // set cursor position after the inserted text
        const newCursorPos = startPos + value.length;
        inputEl.setSelectionRange(newCursorPos, newCursorPos);
        // focus the input
        inputEl.focus();
        try {
          setInput(inputData);

          let result = eval(inputData.replaceAll('%', '/100')).toString();
          setCalculationHistory((prevHistory) => [...prevHistory, `${input + '%'} = ${result.includes('.') ? numberWithCommas(parseFloat(result || '').toFixed(4)) : result}`]);
          setOutput(result);
        } catch (error) {
          setOutput('Error');
        }
      }
    }
    if (percentCount == 1) {
      percentFuntion()
    }

  }, [percentCount])

  const handlePercentage = () => {
    if (keyPress !== 'Enter') {
      setPercentCount(1);
    }
  };

  const handleNegation = async () => {
    let prevInput = input;
    let lastOperator = '';
    const operators = ['+', '-', '/', '*'];
    let hyphenCount = (prevInput.match(/(?<=.)-/g) || []).length;
    if (prevInput.includes('+') || hyphenCount > 0 || prevInput.includes('*') || prevInput.includes('/')) {
      for (let i = prevInput.length - 1; i >= 0; i--) {
        const char = prevInput[i];
        if (char === '+' || char === '-' || char === '*' || char === '/') {

          lastOperator = char;
          const arr = prevInput.split("");
          if (prevInput[i - 1] == '*' && char === '-') {
            arr[i] = '';
          } else if (prevInput[i - 1] == '/' && char === '-') {
            arr[i] = '';
          } else if (char === '*') {
            arr[i] = '*-';
          } else if (char === '/') {
            arr[i] = '/-';
          } else if (char === '+') {
            arr[i] = '-';
          } else if (char === '-') {
            arr[i] = '+';
          }
          prevInput = arr.join("");
          break;
        }
      }
    } else {
      if (prevInput.charAt(0) === "-") {
        prevInput = prevInput.slice(1);
      } else {
        if (prevInput === '0') {
          prevInput = prevInput;
        } else {
          prevInput = '-' + prevInput;
        }

      }
    }
    setInput(prevInput);
    if (operators.includes(prevInput.charAt(prevInput.length - 1)) === false) {
      try {
        let result = await eval(prevInput.replaceAll('%', '/100')).toString();
        setOutput(result);
      } catch (error) {
        if (input === '0') {
          setOutput('0');
        } else {
          setOutput('Error');
        }
      }
    }

    // Move cursor to the end of the input field
    inputRef.current.focus();
    inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
  };

  // const operators = ['+', '-', '/', '*'];
  // let valueAfterLastOperator = '';
  // let expression = prevInput;
  // let valueBeforeFirstOperator = ''
  // for (let i = operators.length - 1; i >= 0; i--) {
  //   const operator = operators[i];
  //   if (!expression.includes(operator)) {
  //     valueBeforeFirstOperator = expression;
  //   } else {
  //     valueBeforeFirstOperator = '';
  //   }
  //   const parts = expression.split(operator);

  //   if (parts.length > 1) {
  //     valueAfterLastOperator = parts[parts.length - 1];
  //     break;
  //   }
  // }

  const handleClear = () => {
    if (keyPress !== 'Enter') {
      setInput('0');
      setOutput('');
    }
  };

  const handleDelete = async () => {
    const inputVal = inputRef.current;
    const start = inputVal.selectionStart;
    const end = inputVal.selectionEnd;
    let inputValue = inputVal.value === '' ? '0' : inputVal.value;
    // Delete the character before the cursor
    let newValue;
    if (start === end) {
      newValue = inputValue.slice(0, start - 1) + inputValue.slice(end);
      inputVal.value = newValue;
      inputVal.setSelectionRange(start - 1, start - 1);
      inputVal.focus();
    } else {
      newValue = inputValue.slice(0, start) + inputValue.slice(end);
      inputVal.value = newValue;
      inputVal.setSelectionRange(start, start);
      inputVal.focus();
    }


    if (inputValue.length !== newValue.length) {
      setInput(newValue === '' ? '0' : newValue);

      if (!['+', '-', '*', '/'].includes(newValue[newValue.length - 1])) {
        try {
          newValue = newValue === '' ? '0' : newValue;
          const result = await eval(newValue.replaceAll('%', '/100')).toString();
          setOutput(result);
        } catch (error) {
          if (newValue === '' || newValue === '0') {
            setOutput('')
          } else {
            setOutput('Error');
          }
        }
      } else {
        try {
          newValue = newValue.slice(0, -1) === '' ? '0' : newValue.slice(0, -1);
          const result = await eval(newValue.replaceAll('%', '/100')).toString();
          setOutput(result);
        } catch (error) {
          if (newValue === '0') {
            setOutput('')
          } else {
            setOutput('Error');
          }

        }
      }
    } else {
      const val = inputValue.slice(0, start - 2);
      setInput(val === '' ? '0' : val);

      if (!['+', '-', '*', '/'].includes(val[val.length - 1])) {
        try {
          const result = await eval(val.replaceAll('%', '/100')).toString();
          setOutput(result);
        } catch (error) {
          setOutput('Error');
        }
      } else {
        try {
          const result = await eval(val.slice(0, -1).replaceAll('%', '/100')).toString();
          setOutput(result);
        } catch (error) {
          setOutput('Error');
        }
      }
    }
  };



  async function handlePaste(event) {
    event.preventDefault();
    let pastedValue: any = event.clipboardData.getData("Text").replaceAll(',', '');
    pastedValue = pastedValue.trim();
    const regex = /^[-+]?[\d.]+(?:[-+*/][\d.]+)*$/;
    if (regex.test(pastedValue)) {
      pastedValue = pastedValue
      // pastedValue = (isNaN(pastedValue) || pastedValue === 'NaN') ? '' : pastedValue.toString();
    } else {
      if (input === '0') {
        pastedValue = '';
        setOutput('invalid input');
        return
      } else {
        pastedValue = '';
      }
    }
    const inputEl: any = inputRef.current;
    const startPos = inputEl.selectionStart;
    const endPos = inputEl.selectionEnd;
    const currentValue = inputEl.value;
    // let inputData = currentValue.slice(0, startPos) + pastedValue + currentValue.slice(endPos);
    let inputData = '';
    if (currentValue.slice(0, startPos) === '0' || currentValue.slice(0, startPos) === '0') {
      inputData = pastedValue
    }
    else {
      if (['+', '-'].includes(currentValue.slice(0, startPos).charAt(currentValue.slice(0, startPos).length - 1))) {
        if (['+', '-'].includes(pastedValue.charAt(0))) {

          inputData = currentValue.slice(0, startPos).slice(0, -1) + pastedValue + currentValue.slice(endPos);
        } else {
          inputData = currentValue.slice(0, startPos) + pastedValue + currentValue.slice(endPos);
        }
      } else {
        console.log(pastedValue, 'pastedValue')
        inputData = currentValue.slice(0, startPos) + pastedValue + currentValue.slice(endPos);
      }
    }
    //  currentValue.slice(0, startPos) + pastedValue + currentValue.slice(endPos);


    // Update the input value and reset selection
    inputEl.value = inputData;
    // set cursor position after the inserted text'
    setTimeout(() => {
      // set cursor position after the inserted text
      const newCursorPos = startPos + pastedValue.length;
      inputEl.setSelectionRange(newCursorPos, newCursorPos);
      // focus the input
      inputEl.focus();
    }, 10);

    if (parseFloat(inputData) !== undefined) {
      try {
        let result = await eval(inputData.replaceAll('%', '/100')).toString();
        setOutput(result);
      } catch (error) {
        if (pastedValue === '') {
          setOutput(output);
        } else {
          setOutput('Error');
        }
      }
      setInput((prevInput) => (currentValue === '0' ? pastedValue : inputData));
    } else {
      setInput('0');
      setOutput('Error');
    }
  }

  const handleDrop = async (event) => {
    event.preventDefault();
    let drag_and_drop: any = event.dataTransfer.getData('text').replaceAll(',', '').trim();
    const regex = /^[-+]?[\d.]+(?:[-+*/][\d.]+)*$/;
    if (regex.test(drag_and_drop)) {
      drag_and_drop = drag_and_drop
      // pastedValue = (isNaN(pastedValue) || pastedValue === 'NaN') ? '' : pastedValue.toString();
    } else {
      if (input === '0') {
        drag_and_drop = '';
        setOutput('invalid input');
        return
      } else {
        drag_and_drop = '';
      }
    }

    // if (regex.test(drag_and_drop)) {
    //   drag_and_drop = parseFloat(drag_and_drop || '');
    //   drag_and_drop = (isNaN(drag_and_drop) || drag_and_drop === 'NaN') ? '' : drag_and_drop.toString();
    // } else {
    //   drag_and_drop = '';
    // }

    let data = '';
    if (parseFloat(drag_and_drop) !== undefined) {
      try {
        input = input === '0' ? '' : input
        if (['+', '-', '/', '*'].includes(input.charAt(input.length - 1))) {
          if (['+', '-', '/', '*'].includes(drag_and_drop.charAt(0))) {
            data = input.slice(0, -1) + drag_and_drop.trim().replaceAll('%', '/100');
          } else {
            data = input + drag_and_drop.trim().replaceAll('%', '/100');
          }
        } else {
          data = input + drag_and_drop.trim().replaceAll('%', '/100');
          console.log("🚀 ~ file: FixedPlugin.tsx:688 ~ handleDrop ~ data:", data)
        }
        // let data = input + drag_and_drop.trim().replaceAll('%', '/100');
        data = data === '' ? '0' : data
        let result = await eval((data.charAt(0) === '0' && data.charAt(1) !== '.') ? data.slice(1) : data).toString();
        setOutput(result);
      } catch (error) {
        if (drag_and_drop === '') {
          setOutput(output);
        } else {
          setOutput('Error');
        }

      }
      setInput((prevValue) => (data.trim().replaceAll(',', '')));
    } else {
      setInput('0');
      setOutput('Error');
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };


  const [color, setColor] = useState("#db6cfa");
  const styles = {
    marginRight: '30%', marginTop: '-8px',
    cursor: 'pointer',
    color: color
  }
  const styles1: any = {

    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 0,
    marginLeft: "5px",
    marginRight: "5px"
  }


  const viewCalculator = () => {
    switch (calculatorFlag) {
      case true:
        return (
          <>
            <div className="calculator" style={{ transform: 'translateX(-86%)' }}>
              <div >
                <Row className="p-1">
                  <Col md='8'>
                    <div className="output1 text-left">Instant Calculator</div>
                  </Col>
                  <Col md='2'>
                    <VscHistory size={20} style={{ marginLeft: '100%', cursor: 'pointer' }} onClick={() => {
                      setHistory(!history);
                    }} />
                  </Col>
                  <Col md='2'>
                    <IoMdClose className="pr-2" size={35}
                      style={styles}
                      onMouseEnter={() => setColor("white")}
                      onMouseLeave={() => setColor("#db6cfa")}
                      onClick={() => {
                        setCalculatorFlag(false);
                        setHistory(false);
                      }}
                    />
                  </Col>
                </Row>

              </div>
              {history ? (
                <>
                  <h2 className="output1">History</h2>
                  <Row>
                    <Col className="float-right pr-4">
                      <RiDeleteBin5Line onClick={() => { setCalculationHistory([]) }} size={20} style={{ cursor: 'pointer' }} />
                    </Col>
                  </Row>
                  <div className='scrollable1-div'>
                    {calculationHistory.map((calculation, index) => {
                      return (
                        <div key={index}>
                          <Container fluid>
                            <Row>
                              <Col className="output1 text-right mt-2" >
                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                  setHistory(!history);
                                  setInput(calculation.split('=')[0].trim());
                                  setOutput(calculation.split('=')[1].trim());
                                }}><span style={{ fontSize: '10px' }}>{calculation.split('=')[0].trim()}</span> = {calculation.split('=')[1].trim()}</div>
                              </Col>
                            </Row>
                          </Container>
                        </div>

                      )
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div className="display">
                    {/* <div className="output1 text-right pr-3 pt-1" style={{ height: '25px' }}>
                      {(output === null || output === undefined) ? '' : ((output === 'Error' || 'invalid input') ? 
                      output : numberWithCommas(parseFloat(output).toFixed(4).replace(/\.?0+$/, '')))}
                    </div> */}
                    <div className="output1 text-right pr-3 pt-1" style={{ height: '25px' }}>
                      {output === null || output === undefined ? (
                        ''
                      ) : output === 'Error' || output === 'invalid input' ? (
                        output
                      ) : (
                        (() => {
                          const formattedNumber = parseFloat(output || "0").toFixed(4).replace(/\.?0+$/, '');
                          const numberWithCommas = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                          const parts = numberWithCommas.split('.');
                          return (parts[0] + (parts[1] ? `.${parts[1].replace(',', '')}` : '') || '');

                          // return numberWithCommas || '';
                          // const formattedNumber = parseFloat(output || "0").toFixed(4);
                          // const parts = formattedNumber.split('.');
                          // const integerPart = parts[0] === '0' ? '0' : parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                          // const decimalPart = parts[1] || '';
                          // return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;

                        })()
                      )}
                    </div>
                    <div className="form-group">
                      <input type="text" ref={inputRef}
                        autoFocus={true}
                        style={{ width: '235px' }}
                        onChange={handleInputChange}
                        onFocus={() => setIsInput1Focused(true)}
                        onBlur={() => setIsInput1Focused(false)}
                        className={'form-control text-right'}
                        value={input}

                        onPaste={handlePaste}
                        onDragOver={handleDragOver} onDrop={handleDrop} />
                    </div>
                  </div>
                  <div className="buttons">
                    <button onClick={() => {
                      setKeyPress('')
                      handlePercentage()
                    }} className="btn btn-secondary" style={styles1}>%</button>
                    <button onClick={() => {
                      setKeyPress('');
                      setOutput('');
                      handleClear();
                      const inputVal = inputRef.current;
                      inputVal.focus();
                    }} className="btn btn-secondary" style={styles1}>C</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleDelete();
                      const inputVal = inputRef.current;
                      inputVal.focus();
                    }} className="btn btn-secondary" style={styles1}>←</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('/')
                    }} className="btn btn-secondary" style={styles1}>÷</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('7')
                    }} className="btn btn-light" style={styles1}>7</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('8')
                    }} className="btn btn-light" style={styles1}>8</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('9')
                    }} className="btn btn-light" style={styles1}>9</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('*')
                    }} className="btn btn-secondary" style={styles1}>×</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('4')
                    }} className="btn btn-light" style={styles1}>4</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('5')
                    }} className="btn btn-light" style={styles1}>5</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('6')
                    }} className="btn btn-light" style={styles1}>6</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('-')
                    }} className="btn btn-secondary" style={styles1}>-</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('1')
                    }} className="btn btn-light" style={styles1}>1</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('2')
                    }} className="btn btn-light" style={styles1}>2</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('3')
                    }} className="btn btn-light" style={styles1}>3</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('+')
                    }} className="btn btn-secondary" style={styles1}>+</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleNegation()
                    }} className="btn btn-secondary" style={styles1}>+/-</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('0')
                    }} className="btn btn-light" style={styles1}>0</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('.')
                    }} className="btn btn-light" style={styles1}>.</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleEqual();
                      const inputVal = inputRef.current;
                      inputVal.focus();
                    }} className="btn btn-primary" style={styles1}>=</button>
                  </div>
                </>
              )

              }
            </div>
          </>

        );
      default:
        return '';
    }
  };
  return (
    <div className="fixed-plugin">
      <div >
        <a
          href="#pablo"
          onClick={(e) => {
            e.preventDefault();
            setHistory(false);
            // setCalculatorFlag(!calculatorFlag)
          }}
        >
          <RiCalculatorFill color="#ffffff" size={40} onClick={() => setCalculatorFlag(!calculatorFlag)} />
        </a>
      </div>
      {viewCalculator()}
    </div>
  );
};

export default FixedPlugin;





// import React, { Component, useEffect, useState } from "react";
// import { AiFillCalculator } from "react-icons/ai";
// import { RiCalculatorFill, RiDeleteBin5Line, RiDeleteBin6Line } from "react-icons/ri";
// import { VscHistory } from "react-icons/vsc"
// import { Container, Row, Col } from 'reactstrap';

// import { Button, CustomInput } from "reactstrap";
// import { numberWithCommas } from "../utils/customFunction";
// import { IoMdClose } from "react-icons/io";
// import { FaCalculator } from "react-icons/fa";

// const FixedPlugin = (props) => {

//   const [calculatorFlag, setCalculatorFlag] = useState(false);

//   let [input, setInput] = useState('0');
//   let [input1, setInput1] = useState(0);
//   let [percent, setPercent] = useState(0);
//   let [output, setOutput] = useState('');
//   let [keyPress, setKeyPress] = useState('');
//   const [history, setHistory] = useState(false);
//   const [calculationHistory, setCalculationHistory] = useState<any>([]);
//   const [input1Focused, setIsInput1Focused] = useState(false);
//   const inputRef: any = React.useRef(null);

//   useEffect(() => {
//     if (input1 >= 1) handleEqual();
//   }, [input1])

//   useEffect(() => {
//     document.addEventListener("keydown", handleKeyDown1);
//     return () => {
//       document.removeEventListener("keydown", handleKeyDown1);
//     };
//   }, []);

//   const handleKeyDown1 = (event) => {
//     if (event.keyCode === 27) { // 27 is the key code for "Esc"
//       setInput("0");
//       setOutput("");
//     }
//     if ((event.keyCode === 13 && event.key === 'Enter') || (event.keyCode === 61 && event.key === '=')) {
//       setInput1(1);
//       // handleEqual()
//     }
//     // if (event.key === 'Delete') {
//     //   handleClear();
//     // }

//   };

//   const handleEqual = () => {
//     setInput1(0)
//     if (input !== '') {
//       if (input !== '0') {
//         try {

//           let result = eval(input.replaceAll('%', '/100')).toString();
//           setOutput(result);
//           setCalculationHistory((prevHistory) => [...prevHistory, `${input.toString()} = ${result.includes('.') ? numberWithCommas(parseFloat(result || '').toFixed(4)) : result}`]);
//           setInput('0')

//         } catch (error) {
//           setOutput('Error');
//         }
//       }
//     }
//   };

//   const checkOperator = (expression) => {
//     const operators = ["*-", "/-"];
//     for (const operator of operators) {
//       if (expression.includes(operator)) {
//         const index = expression.indexOf(operator);
//         if (index + 2 < expression.length && /[+\-*/]/.test(expression[index + 2])) {
//           return false;
//         }
//       }
//     }
//     return true;
//   };
//   // const checkDoubleOperator = (expression) => {
//   //   const operators = ["+", "-", "*", "/"];
//   //   for (let i = 0; i < expression.length - 1; i++) {
//   //     const currentChar = expression[i];
//   //     const nextChar = expression[i + 1];
//   //     if (operators.includes(currentChar) && operators.includes(nextChar)) {
//   //       return false;
//   //     }
//   //   }
//   //   return true;
//   // };
//   const checkDoubleOperator = (expression) => {
//     const operators = ["+", "-", "*", "/"];
//     for (let i = 0; i < expression.length - 1; i++) {
//       const currentChar = expression[i];
//       const nextChar = expression[i + 1];
//       if (operators.includes(currentChar) && currentChar === nextChar) {
//         return false;
//       }
//     }
//     return true;
//   };

//   function checkDecimalPoints(data) {
//     for (let i = 0; i < data.length; i++) {
//       const decimalCount = data[i].split('.').length - 1;
//       if (decimalCount > 1) {
//         return false;
//       }
//     }
//     return true;
//   }

//   const handleInputChange = async (event) => {
//     const { value, selectionStart, selectionEnd } = event.target;
//     let valueBeforeCursor = value.substring(0, selectionStart);
//     let valueAfterCursor = value.substring(selectionStart);
//     let inputValue = event.target.value === '' ? '0' : event.target.value;
//     // Validate the input
//     if (inputRef.current.selectionStart === 1 && inputValue === '0' && input !== '0.') {
//       // Do not update the input value
//       return;
//     } else {
//       let pointVal = ''
//       const operators = ['*', '/', '-', '+', '%'];
//       let valueAfterLastOperator = '';
//       let expression = valueBeforeCursor;
//       let regixoperators = /[*/+\-%]/g;
//       let matches = expression.match(regixoperators);
//       let lastOperator = matches ? matches.pop() : null;
//       let valueBeforeFirstOperator = ''
//       // for (let i = operators.length - 1; i >= 0; i--) {
//       // const operator = operators[i];

//       if (!expression.includes(lastOperator)) {
//         valueBeforeFirstOperator = expression;
//       } else {
//         valueBeforeFirstOperator = '';
//       }
//       const parts = expression.split(lastOperator);

//       if (parts.length > 1) {
//         valueAfterLastOperator = parts[parts.length - 1];
//         // break;
//       }
//       // }
//       // if (valueAfterLastOperator === '.') {
//       //   inputValue = inputValue.slice(0, -1) + '0.'

//       // }
//       if (valueAfterLastOperator === '.') {
//         const modifiedValue = valueBeforeCursor.slice(0, -1) + '0.' + valueAfterCursor;

//         // Set the modified value in the input element
//         inputRef.current.value = modifiedValue;
//         inputValue = modifiedValue;

//         // Calculate the new cursor position
//         const newCursorPosition = (valueBeforeCursor.length - 1) + '0.'.length;

//         // Set the selection range for the input element
//         inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);

//         // Focus the input
//         inputRef.current.focus();
//       }
//       let flag = checkDecimalPoints(inputValue.split(/[\+\-\*\/%]/g).filter(operator => operator !== ''));
//       if (event.nativeEvent.inputType === 'deleteContentBackward' || event.nativeEvent.inputType === 'deleteContentForward') {
//         // setInput(inputValue === '' ? '0' : (inputValue === '.' ? '0' + inputValue : inputValue));
//         setInput(inputValue === '' ? '0' : inputValue);
//         try {
//           let result = await eval(inputValue.replaceAll('%', '/100'));
//           setOutput(result);
//         } catch (e) {
//           // If the input is invalid but not empty, keep the current output
//           if (inputValue !== "") {
//             return;
//           }
//           setOutput("");
//         }
//       } else {
//         const isValidExpression =
//           // /^([0-9]|([+\-*.\/%]))+$/.test(inputValue) &&
//           flag &&
//           /^[0-9+\-*.\/%]+(\.[0-9]+)?$/.test(inputValue) &&
//           (valueBeforeFirstOperator.match(/\./g) || []).length <= 1 &&
//           (valueAfterLastOperator.match(/\./g) || []).length <= 1 &&
//           !valueAfterLastOperator.startsWith('00') &&
//           !inputValue.endsWith('%%') &&
//           !/(\+[\-]|[\-]\+)/.test(inputValue) &&
//           // !/([+\-*.\/]){2,}/.test(inputValue)   // Disallow consecutive operators
//           !/([+\/*]{2,}|(?<=\d)-{2,})/.test(inputValue) && checkOperator(inputValue) && checkDoubleOperator(inputValue)  // Disallow consecutive operators

//         if (isValidExpression) {
//           if (inputValue.charAt(0) === '0' && inputValue.charAt(1) != '.') {
//             // Remove the leading zero
//             inputValue = inputValue.slice(1);
//           }
//           setInput(inputValue === '' ? '0' : inputValue === '.' ? '0' + inputValue : inputValue);

//           let operatorAtLast = ['+', '-', '*', '/'].includes(inputValue.charAt(inputValue.length - 1));
//           inputValue = operatorAtLast ? inputValue.slice(0, -1) : inputValue;
//           if ((['+', '-', '*', '/'].includes(inputValue.charAt(inputValue.length - 1)) === false)) {
//             try {
//               let result = await eval(inputValue.replaceAll('%', '/100')).toString();
//               setOutput(result);
//             } catch (e) {
//               // If the input is invalid but not empty, keep the current output
//               setOutput("Error");
//               if (inputValue !== "") {
//                 return;
//               }

//             }
//           }
//         } else {
//           if (selectionStart > 0 && selectionStart < value.length) {
//             setInput((prevValue) => {
//               // let newValue = prevValue.slice(0, selectionStart) + '.' + prevValue.slice(selectionStart);
//               // Calculate the new cursor position after adding the equal sign
//               const newCursorPosition = selectionStart + 1;
//               // Move the cursor to the desired position
//               setTimeout(() => {
//                 event.target.setSelectionRange(newCursorPosition - 2, newCursorPosition - 2);
//               }, 0.0001);
//               prevValue = prevValue.slice(0, selectionStart) + prevValue.slice(selectionStart)
//               return prevValue;
//             });
//           }
//         }
//       }
//     }
//   };

//   const handleInput1 = async (value) => {
//     if (value === '0' && (input === '0' || input.length === 0)) {
//       // Do not update the input value
//       return;
//     } else {

//       // if (isValidExpression) {
//       const inputEl: any = inputRef.current;
//       const startPos = inputEl.selectionStart;
//       const endPos = inputEl.selectionEnd;
//       const currentValue = inputEl.value;
//       let inputData = currentValue.slice(0, startPos) + value + currentValue.slice(endPos)
//       const operators = ['*', '/', '-', '+', '%'];
//       let valueAfterLastOperator = '';
//       let expression = currentValue.slice(0, startPos) + value;
//       let regixoperators = /[*/+\-%]/g;
//       let matches = expression.match(regixoperators);
//       let lastOperator = matches ? matches.pop() : null;
//       let valueBeforeFirstOperator = ''
//       // for (let i = operators.length - 1; i >= 0; i--) {
//       // const operator = operators[i];

//       if (!expression.includes(lastOperator)) {
//         valueBeforeFirstOperator = expression;
//       } else {
//         valueBeforeFirstOperator = '';
//       }
//       const parts = expression.split(lastOperator);
//       if (parts.length > 1) {
//         valueAfterLastOperator = parts[parts.length - 1];
//         // break;
//       }
//       // }
//       if (valueAfterLastOperator === '.') {
//         value = '0.'
//         inputData = currentValue.slice(0, startPos) + value + currentValue.slice(endPos)
//       }
//       // const operators = ['*', '-', '/', '+'];
//       // let valueAfterLastOperator = '';
//       // let expression = currentValue.slice(0, startPos) + value;
//       // let valueBeforeFirstOperator = ''
//       // for (let i = operators.length - 1; i >= 0; i--) {
//       //   const operator = operators[i];
//       //   if (!expression.includes(operator)) {
//       //     valueBeforeFirstOperator = expression;
//       //   } else {
//       //     valueBeforeFirstOperator = '';
//       //   }
//       //   const parts = expression.split(operator);

//       //   if (parts.length > 1) {
//       //     valueAfterLastOperator = parts[parts.length - 1];
//       //     break;
//       //   }
//       // }
//       // if (valueAfterLastOperator === '.') {
//       //   value = '0.'
//       // }
//       let flag1 = checkDecimalPoints(inputData.split(/[\+\-\*\/%]/g).filter(operator => operator !== ''))
//       const isValidExpression =
//         flag1 &&
//         /^[0-9+\-*.\/%]+(\.[0-9]+)?$/.test(currentValue.slice(0, startPos) + value) &&
//         (valueBeforeFirstOperator.match(/\./g) || []).length <= 1 &&
//         (valueAfterLastOperator.match(/\./g) || []).length <= 1 &&
//         !valueAfterLastOperator.startsWith('00') &&
//         !(currentValue.slice(0, startPos) + value).endsWith('%%') &&
//         !/([+.\/*]{2,}|(?<=\d)-{2,})/.test(currentValue.slice(0, startPos) + value) && !/(\+[\-]|[\-]\+)/.test(currentValue.slice(0, startPos) + value) && checkOperator(currentValue.slice(0, startPos) + value)

//       let flag = false;
//       if (currentValue.slice(endPos).length) {
//         flag = true
//       }
//       if (isValidExpression && checkOperator((currentValue).slice(0, startPos) + value) === true && checkDoubleOperator((currentValue).slice(0, startPos) + value) === true) {

//         let outputVal = (currentValue).slice(0, startPos) + value + currentValue.slice(endPos);

//         setInput((prevInput) => (outputVal.charAt(0) === '0' && outputVal.charAt(1)) == '.' ? outputVal : (outputVal.charAt(0) === '0' ? outputVal.substring(1) : outputVal));

//         if (flag || (['+', '-', '*', '/'].includes(value) === false)) {
//           if (keyPress !== 'Enter') {
//             // Update the input value and reset selection
//             inputData = (inputData.charAt(0) === '0' && inputData.charAt(1) !== '.') ? inputData.slice(1) : inputData;
//             inputEl.value = inputData;
//             // set cursor position after the inserted text
//             const newCursorPos = startPos + value.length;
//             inputEl.setSelectionRange(newCursorPos, newCursorPos);
//             // focus the input
//             inputEl.focus();
//             let operatorAtLast = ['+', '-', '*', '/'].includes(inputData.charAt(inputData.length - 1));
//             inputData = operatorAtLast ? inputData.slice(0, -1) : inputData
//             try {
//               let result = await eval((['+', '-', '*', '/', '%'].includes(inputData.charAt(inputData.length - 2)) && inputData.charAt(inputData.length - 1) === '.') ? inputData.replace(inputData.charAt(inputData.length - 1), '0.').replaceAll('%', '/100') : inputData.replaceAll('%', '/100')).toString();
//               setOutput(result);
//             } catch (error) {
//               if (input === '0') {
//                 setOutput('0');
//               } else {
//                 setOutput('Error');
//               }
//             }
//           }
//         } else {
//           inputEl.focus();
//         }
//       }
//       // }
//     }
//   };

//   const [percentCount, setPercentCount] = useState(0);
//   useEffect(() => {
//     const percentFuntion = () => {
//       setPercentCount(0);
//       try {
//         let prev = input.replaceAll('%', '/100');
//         setInput((prevInput) => {
//           if (prevInput.slice(-1) === "%") {
//             prevInput = prevInput.slice(0, -1);
//           }
//           return prevInput + '%'
//         });
//         let result = eval(prev + '/100').toString();
//         setCalculationHistory((prevHistory) => [...prevHistory, `${input + '%'} = ${result.includes('.') ? numberWithCommas(parseFloat(result || '').toFixed(4)) : result}`]);
//         setOutput(result);
//       } catch (error) {
//         setOutput('Error');
//       }
//     }
//     if (percentCount == 1) {
//       percentFuntion()
//     }

//   }, [percentCount])

//   const handlePercentage = () => {
//     if (keyPress !== 'Enter') {
//       setPercentCount(1);
//     }
//   };

//   const handleNegation = async () => {
//     let prevInput = input;
//     let lastOperator = '';
//     const operators = ['+', '-', '/', '*'];
//     let hyphenCount = (prevInput.match(/(?<=.)-/g) || []).length;
//     if (prevInput.includes('+') || hyphenCount > 0 || prevInput.includes('*') || prevInput.includes('/')) {
//       for (let i = prevInput.length - 1; i >= 0; i--) {
//         const char = prevInput[i];
//         if (char === '+' || char === '-' || char === '*' || char === '/') {

//           lastOperator = char;
//           const arr = prevInput.split("");
//           if (prevInput[i - 1] == '*' && char === '-') {
//             arr[i] = '';
//           } else if (prevInput[i - 1] == '/' && char === '-') {
//             arr[i] = '';
//           } else if (char === '*') {
//             arr[i] = '*-';
//           } else if (char === '/') {
//             arr[i] = '/-';
//           } else if (char === '+') {
//             arr[i] = '-';
//           } else if (char === '-') {
//             arr[i] = '+';
//           }
//           prevInput = arr.join("");
//           break;
//         }
//       }
//     } else {
//       if (prevInput.charAt(0) === "-") {
//         prevInput = prevInput.slice(1);
//       } else {
//         if (prevInput === '0') {
//           prevInput = prevInput;
//         } else {
//           prevInput = '-' + prevInput;
//         }

//       }
//     }
//     setInput(prevInput);
//     if (operators.includes(prevInput.charAt(prevInput.length - 1)) === false) {
//       try {
//         let result = await eval(prevInput.replaceAll('%', '/100')).toString();
//         setOutput(result);
//       } catch (error) {
//         if (input === '0') {
//           setOutput('0');
//         } else {
//           setOutput('Error');
//         }
//       }
//     }

//     // Move cursor to the end of the input field
//     inputRef.current.focus();
//     inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
//   };

//   // const operators = ['+', '-', '/', '*'];
//   // let valueAfterLastOperator = '';
//   // let expression = prevInput;
//   // let valueBeforeFirstOperator = ''
//   // for (let i = operators.length - 1; i >= 0; i--) {
//   //   const operator = operators[i];
//   //   if (!expression.includes(operator)) {
//   //     valueBeforeFirstOperator = expression;
//   //   } else {
//   //     valueBeforeFirstOperator = '';
//   //   }
//   //   const parts = expression.split(operator);

//   //   if (parts.length > 1) {
//   //     valueAfterLastOperator = parts[parts.length - 1];
//   //     break;
//   //   }
//   // }

//   const handleClear = () => {
//     if (keyPress !== 'Enter') {
//       setInput('0');
//       setOutput('');
//     }
//   };

//   const handleDelete = async () => {
//     const inputVal = inputRef.current;
//     const start = inputVal.selectionStart;
//     const end = inputVal.selectionEnd;
//     let inputValue = inputVal.value === '' ? '0' : inputVal.value;
//     // Delete the character before the cursor
//     let newValue;
//     if (start === end) {
//       newValue = inputValue.slice(0, start - 1) + inputValue.slice(end);
//       inputVal.value = newValue;
//       inputVal.setSelectionRange(start - 1, start - 1);
//       inputVal.focus();
//     } else {
//       newValue = inputValue.slice(0, start) + inputValue.slice(end);
//       inputVal.value = newValue;
//       inputVal.setSelectionRange(start, start);
//       inputVal.focus();
//     }


//     if (inputValue.length !== newValue.length) {
//       setInput(newValue === '' ? '0' : newValue);

//       if (!['+', '-', '*', '/'].includes(newValue[newValue.length - 1])) {
//         try {
//           const result = await eval(newValue.replaceAll('%', '/100')).toString();
//           setOutput(result);
//         } catch (error) {
//           if (newValue === '' || newValue === '0') {
//             setOutput('')
//           } else {
//             setOutput('Error');
//           }
//         }
//       } else {
//         try {
//           const result = await eval(newValue.slice(0, -1).replaceAll('%', '/100')).toString();
//           setOutput(result);
//         } catch (error) {
//           if (newValue === '0') {
//             setOutput('')
//           } else {
//             setOutput('Error');
//           }

//         }
//       }
//     } else {
//       const val = inputValue.slice(0, start - 2);
//       setInput(val === '' ? '0' : val);

//       if (!['+', '-', '*', '/'].includes(val[val.length - 1])) {
//         try {
//           const result = await eval(val.replaceAll('%', '/100')).toString();
//           setOutput(result);
//         } catch (error) {
//           setOutput('Error');
//         }
//       } else {
//         try {
//           const result = await eval(val.slice(0, -1).replaceAll('%', '/100')).toString();
//           setOutput(result);
//         } catch (error) {
//           setOutput('Error');
//         }
//       }
//     }
//   };



//   async function handlePaste(event) {
//     event.preventDefault();
//     let pastedValue = event.clipboardData.getData("Text").replaceAll(',', '');
//     pastedValue = pastedValue.trim();
//     const inputEl: any = inputRef.current;
//     const startPos = inputEl.selectionStart;
//     const endPos = inputEl.selectionEnd;
//     const currentValue = inputEl.value;
//     let inputData = currentValue.slice(0, startPos) + pastedValue + currentValue.slice(endPos);

//     // Update the input value and reset selection
//     inputEl.value = inputData;
//     // set cursor position after the inserted text
//     const newCursorPos = startPos + pastedValue.length;
//     inputEl.setSelectionRange(newCursorPos, newCursorPos);
//     // focus the input
//     inputEl.focus();

//     if (parseFloat(inputData) !== undefined) {
//       try {
//         let result = await eval(inputData.replaceAll('%', '/100')).toString();
//         setOutput(result);
//       } catch (error) {
//         setOutput('Error');
//       }
//       setInput((prevInput) => (currentValue === '0' ? pastedValue : currentValue.slice(0, startPos) + pastedValue + currentValue.slice(endPos)));
//     } else {
//       setInput('0');
//       setOutput('Error');
//     }
//   }

//   const handleDrop = async (event) => {
//     event.preventDefault();
//     let drag_and_drop = event.dataTransfer.getData('text').replaceAll(',', '');
//     drag_and_drop = drag_and_drop.trim();
//     if (parseFloat(drag_and_drop) !== undefined) {
//       try {
//         let data = input + drag_and_drop.trim().replaceAll('%', '/100');
//         let result = await eval((data.charAt(0) === '0' && data.charAt(1) !== '.') ? data.slice(1) : data).toString();
//         setOutput(result);
//       } catch (error) {
//         setOutput('Error');
//       }
//       setInput((prevValue) => (prevValue === '0' ? '' : prevValue) + drag_and_drop.trim().replaceAll(',', ''));
//     } else {
//       setInput('0');
//       setOutput('Error');
//     }
//   };
//   const handleDragOver = (event) => {
//     event.preventDefault();
//   };


//   const [color, setColor] = useState("#db6cfa");
//   const styles = {
//     marginRight: '30%', marginTop: '-8px',
//     cursor: 'pointer',
//     color: color
//   }
//   const styles1: any = {

//     width: '50px',
//     height: '50px',
//     borderRadius: '50%',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     textAlign: 'center',
//     padding: 0,
//     marginLeft: "5px",
//     marginRight: "5px"
//   }


//   const viewCalculator = () => {
//     switch (calculatorFlag) {
//       case true:
//         return (
//           <>
//             <div className="calculator" style={{ transform: 'translateX(-86%)' }}>
//               <div >
//                 <Row className="p-1">
//                   <Col md='8'>
//                     <div className="output1 text-left">Instant Calculator</div>
//                   </Col>
//                   <Col md='2'>
//                     <VscHistory size={20} style={{ marginLeft: '100%', cursor: 'pointer' }} onClick={() => {
//                       setHistory(!history);
//                     }} />
//                   </Col>
//                   <Col md='2'>
//                     <IoMdClose className="pr-2" size={35}
//                       style={styles}
//                       onMouseEnter={() => setColor("white")}
//                       onMouseLeave={() => setColor("#db6cfa")}
//                       onClick={() => {
//                         setCalculatorFlag(false);
//                         setHistory(false);
//                       }}
//                     />
//                   </Col>
//                 </Row>

//               </div>
//               {history ? (
//                 <>
//                   <h2 className="output1">History</h2>
//                   <Row>
//                     <Col className="float-right pr-4">
//                       <RiDeleteBin5Line onClick={() => { setCalculationHistory([]) }} size={20} style={{ cursor: 'pointer' }} />
//                     </Col>
//                   </Row>
//                   <div className='scrollable1-div'>
//                     {calculationHistory.map((calculation, index) => {
//                       return (
//                         <div key={index}>
//                           <Container fluid>
//                             <Row>
//                               <Col className="output1 text-right mt-2" >
//                                 <div style={{ cursor: 'pointer' }} onClick={() => {
//                                   setHistory(!history);
//                                   setInput(calculation.split('=')[0].trim());
//                                   setOutput(calculation.split('=')[1].trim());
//                                 }}><span style={{ fontSize: '10px' }}>{calculation.split('=')[0].trim()}</span> = {calculation.split('=')[1].trim()}</div>
//                               </Col>
//                             </Row>
//                           </Container>
//                         </div>

//                       )
//                     })}
//                   </div>
//                 </>
//               ) : (
//                 <>
//                   <div className="display">
//                     <div className="output1 text-right pr-3 pt-1" style={{ height: '25px' }}>
//                       {(output === null || output === undefined) ? '' : (output === 'Error' ? 'Error' : numberWithCommas(parseFloat(output).toFixed(4).replace(/\.?0+$/, '')))}
//                       {/* {output?.toString()?.includes('.') ? numberWithCommas(parseFloat(output || '').toFixed(4)) : numberWithCommas(parseFloat(output || ''))} */}
//                     </div>
//                     <div className="form-group">
//                       <input type="text" ref={inputRef}
//                         autoFocus={true}
//                         style={{ width: '235px' }}
//                         onChange={handleInputChange}
//                         onFocus={() => setIsInput1Focused(true)}
//                         onBlur={() => setIsInput1Focused(false)}
//                         className={'form-control text-right'}
//                         value={input}

//                         onPaste={handlePaste}
//                         onDragOver={handleDragOver} onDrop={handleDrop} />
//                     </div>
//                   </div>
//                   <div className="buttons">
//                     <button onClick={() => {
//                       setKeyPress('')
//                       handlePercentage()
//                     }} className="btn btn-secondary" style={styles1}>%</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       setOutput('');
//                       handleClear();
//                       const inputVal = inputRef.current;
//                       inputVal.focus();
//                     }} className="btn btn-secondary" style={styles1}>C</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleDelete();
//                       const inputVal = inputRef.current;
//                       inputVal.focus();
//                     }} className="btn btn-secondary" style={styles1}>←</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleInput1('/')
//                     }} className="btn btn-secondary" style={styles1}>÷</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleInput1('7')
//                     }} className="btn btn-light" style={styles1}>7</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleInput1('8')
//                     }} className="btn btn-light" style={styles1}>8</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleInput1('9')
//                     }} className="btn btn-light" style={styles1}>9</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleInput1('*')
//                     }} className="btn btn-secondary" style={styles1}>×</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleInput1('4')
//                     }} className="btn btn-light" style={styles1}>4</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleInput1('5')
//                     }} className="btn btn-light" style={styles1}>5</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleInput1('6')
//                     }} className="btn btn-light" style={styles1}>6</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleInput1('-')
//                     }} className="btn btn-secondary" style={styles1}>-</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleInput1('1')
//                     }} className="btn btn-light" style={styles1}>1</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleInput1('2')
//                     }} className="btn btn-light" style={styles1}>2</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleInput1('3')
//                     }} className="btn btn-light" style={styles1}>3</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleInput1('+')
//                     }} className="btn btn-secondary" style={styles1}>+</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleNegation()
//                     }} className="btn btn-secondary" style={styles1}>+/-</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleInput1('0')
//                     }} className="btn btn-light" style={styles1}>0</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleInput1('.')
//                     }} className="btn btn-light" style={styles1}>.</button>
//                     <button onClick={() => {
//                       setKeyPress('');
//                       handleEqual();
//                       const inputVal = inputRef.current;
//                       inputVal.focus();
//                     }} className="btn btn-primary" style={styles1}>=</button>
//                   </div>
//                 </>
//               )

//               }
//             </div>
//           </>

//         );
//       default:
//         return '';
//     }
//   };
//   return (
//     <div className="fixed-plugin">
//       <div >
//         <a
//           href="#pablo"
//           onClick={(e) => {
//             e.preventDefault();
//             setHistory(false);
//             // setCalculatorFlag(!calculatorFlag)
//           }}
//         >
//           <RiCalculatorFill color="#ffffff" size={40} onClick={() => setCalculatorFlag(!calculatorFlag)} />
//         </a>
//       </div>
//       {viewCalculator()}
//     </div>
//   );
// };

// export default FixedPlugin;















