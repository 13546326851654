import React from 'react';
// import Header from './../components/Header';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import { getAmc } from './../stores/services/amc.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  getFundByAmc,
  UpdateNavOfAllFunds,
  addDailyNav,
  getDailyNavOfAllFunds
} from './../stores/services/funds.service';
import { AiFillPlusCircle } from 'react-icons/ai';
import { BsTable } from 'react-icons/bs';
import moment from 'moment';
import { convertToObject } from 'typescript';
import { isNumber } from '../utils/customFunction';

const NavComponent = () => {
  let date: any = new Date();

  const email = sessionStorage.getItem('email') || '';
  const amcCode = sessionStorage.getItem('amc_code') || '';
  const [Loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [amcdata, setAmcdata] = useState<any>([]);
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);
  const [offerPrice, setOfferPrice] = useState<boolean>(false);
  const [redmptionPrice, setRedmptionPrice] = useState<boolean>(false);
  const [totalNetAsset, setTotalNetAsset] = useState<boolean>(false);
  const [totalFundUnit, seTotalFundUnit] = useState<boolean>(false);
  const [NAVdate, setNAVDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [navFlag, setNavFlag] = useState(false);

  const [allDailyNav, setAllDailyNav] = useState<any>([]);


  React.useEffect(() => {
    const fetchAmc = async () => {
      amcdata.length = 0;
      setAmcdata(amcdata);
      //get all Amc for dropdown
      try {
        const amcResponse = await getAmc(email);
        setAmcdata(amcResponse.data.data);
      } catch (error) { }
    };
    fetchAmc();
  }, []);

  // it just remove fund_ from Symbol_code 
  const extractKey = (symbolCode: any) => symbolCode.includes('FUND_') ? symbolCode.split('_')[1] : symbolCode;
  const [dailyNav, setDailyNav] = useState<any>([]);
  const getDailyNav = async () => {
    // if (NAVdate !== moment(date).format('YYYY-MM-DD')) {
    //   setTotalNetAsset(true);
    //   seTotalFundUnit(true);
    //   setOfferPrice(true);
    //   setRedmptionPrice(true);
    // }
    setLoading(true);
    setAccFundLoading(true);
    allDailyNav.length = 0;
    setAllDailyNav(allDailyNav);
    try {
      const response = await getDailyNavOfAllFunds(email, amcCode, NAVdate);
      let fooArray: any = Object.entries(response.data);
      setNavFlag(false)
      let foo = fooArray.flat().map((item) => {
        if (typeof (item) !== 'string') {

          let total_asset: any = false;
          let flag_total_fund_u: any = false;
          let flag_offer_p: any = false;
          let flag_redemption_p: any = false;
          if (parseFloat(isNumber(item.nav)) == 0 || parseFloat(isNumber(item.nav)) === Infinity || parseFloat(isNumber(item.total_net_asset)) === 0 || parseFloat(isNumber(item.total_fund_units)) === 0 || parseFloat(isNumber(item.redemption_price)) === 0 || parseFloat(isNumber(item.offer_price)) === 0) {
            setNavFlag(true);
          }
          if (item.total_net_asset > 0) {
            total_asset = true;
          } else {
            total_asset = false
          }

          if (item.total_fund_units > 0) {
            flag_total_fund_u = true
          } else {
            flag_total_fund_u = false
          }
          if (item.redemption_price > 0) {
            flag_redemption_p = true
          } else {
            flag_redemption_p = false
          }
          if (item.offer_price > 0) {
            flag_offer_p = true
          } else {
            flag_offer_p = false
          }
          if (item.sma !== true) {
            return { ...item, flag_total_asset: total_asset, flag_total_fund_units: flag_total_fund_u, flag_offer_price: flag_offer_p, flag_redemption_price: flag_redemption_p }
          }

        }
      })
      setAllDailyNav(foo.filter(x => x !== undefined));
      setAccFundLoading(false);
      setLoading(false);
    } catch (error) { }
    setAccFundLoading(false);
    setLoading(false);
  };


  const [code, setCode] = useState('');

  //render dropdown for amc data
  const renderAmcDropdown = () => {
    return amcdata.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code}>
          {item.name} ({item.amc_code})
        </option>
      );
    });
  };


  const AddNavValue = async (obj: any) => {
    setTotalNetAsset(true);
    seTotalFundUnit(true);
    setOfferPrice(true);
    setRedmptionPrice(true);
    setUpdateLoading(true);
    try {
      const response = await addDailyNav(email, allDailyNav);
      if (response.data.status === 200) {
        setTotalNetAsset(false);
        seTotalFundUnit(false);
        setOfferPrice(false);
        setRedmptionPrice(false);
        getDailyNav();
        toast.success('NAV updated');
        setUpdateLoading(false);
      }
      setUpdateLoading(false);
    } catch (error: any) {
      setTotalNetAsset(false);
      seTotalFundUnit(false);
      setOfferPrice(false);
      setRedmptionPrice(false);
      toast.error(error.response.data.message);
      setUpdateLoading(false);
    }
  };

  const decimalValue = (value, len) => {
    if (value === 0 || value === parseInt(value, 10)) return value;
    value = value.toString();
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  const renderData = () => {
    return allDailyNav.map((items: any, index: any) => {
      items.offer_rate = 'sale';
      return (
        <tr key={index}>
          <td>{items.fund}</td>
          <td>
            <div className="form-group">
              <input type="number" className="form-control"
                value={items.total_net_asset || 0}
                readOnly={totalNetAsset === true ? totalNetAsset : items.flag_total_asset}
                id={`txt-tna-${index}`}
                onChange={(e) => {
                  items.total_net_asset = decimalValue(e.target.value, 2);
                  items.nav = (isFinite(items.total_net_asset / items.total_fund_units) ? items.total_net_asset / items.total_fund_units : 0).toFixed(4) || 0;;
                  //  setAllDailyNav(dailynav => ([...dailynav, items]))
                  setCode(e.target.value);

                }}
              />
            </div>
          </td>
          <td>
            <div className="form-group">
              <input type="number" className="form-control"
                value={items.total_fund_units || 0}
                readOnly={totalFundUnit === true ? totalFundUnit : items.flag_total_fund_units}
                id={`txt-tfu-${index}`}
                onChange={(e) => {
                  items.total_fund_units = decimalValue(e.target.value, 4);
                  items.nav = (isFinite(items.total_net_asset / items.total_fund_units) ? items.total_net_asset / items.total_fund_units : 0).toFixed(4) || 0;;
                  setCode(e.target.value);
                }}

              />
            </div>
          </td>
          <td>
            <div className="form-group">
              <input type="number" className="form-control" readOnly
                value={items.nav || 0}
                onChange={(e) => {
                  var a = decimalValue(e.target.value, 4);
                  items.nav = a.replace("-", "");
                  setCode(e.target.value.replace("-", ""));
                }}
              />
            </div>

          </td>
          <td>
            <input type="number" className="form-control"
              value={items.offer_price || 0}
              readOnly={offerPrice === true ? offerPrice : items.flag_offer_price}
              onChange={(e) => {
                var a = decimalValue(e.target.value, 4);
                items.offer_price = a || 0;
                setCode(e.target.value);
              }}
            />
          </td>
          <td>
            <input type="number" className="form-control"
              value={items.redemption_price || 0}
              readOnly={redmptionPrice === true ? redmptionPrice : items.flag_redemption_price}
              onChange={(e) => {
                var a = decimalValue(e.target.value, 4);
                items.redemption_price = a || 0;
                setCode(e.target.value);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              {/* <Header /> */}
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Change NAV of Funds</h4>
                </div>
                <div className="card-body">
                  <div className="row">

                    <div className="col-md-3">
                      <div className="form-group">
                        <label>NAV Date:</label>
                        <input type="date" className="form-control"
                          value={NAVdate}
                          onChange={(e) => {
                            setNAVDate(e.target.value);

                          }} />
                      </div>
                    </div>

                    <div className="col-md-2">
                      <label ></label>
                      <button className="btn btn-primary btn-block"
                        disabled={Boolean(Loading)}
                        onClick={() => {
                          // setTotalNetAsset(false);
                          // seTotalFundUnit(false);
                          // setOfferPrice(false);
                          // setRedmptionPrice(false);
                          getDailyNav();
                        }}>Load</button>
                    </div>

                  </div>

                  {Loading ? (
                    <div className="d-flex justify-content-center my-5">
                      <i className="fa fa-spinner fa-spin fa-3x" ></i>
                    </div>
                  ) : (
                    allDailyNav.length > 0 ? (
                      <>
                        <div className='table-responsive'>
                          <table className="table" id="myTable">
                            <thead>
                              <tr>
                                <th>Fund</th>
                                <th>Total Net Assets</th>
                                <th>Total Fund Units Issued</th>
                                <th>Daily NAV</th>
                                <th>Offer Price</th>
                                <th>Redemption Price</th>
                              </tr>
                            </thead>
                            <tbody id="myUL">{renderData()}</tbody>
                          </table>
                        </div>
                        {/* {(navFlag === true && NAVdate === moment(date).format('YYYY-MM-DD')) && ( */}
                        {(navFlag === true ) && (
                          <div className="">
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                AddNavValue(allDailyNav);
                              }}
                              disabled={Boolean(updateLoading)}
                            >
                              {updateLoading ? (
                                <>
                                  <span
                                    className="spinner-border login-txt spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="login-txt"> Loading...</span>
                                </>
                              ) : (
                                <span>Update</span>
                              )}
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="table-empty">
                        <BsTable className="icon" />
                        <p className="text-center empty-text">Empty</p>
                      </div>
                    )


                  )}
                </div>


              </div>
            </Container>

          </Col>
        </Row>
      </div>


    </>
  );
};

export default NavComponent;

