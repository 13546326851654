import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

//add addMegaTransaction
const addMegaTransaction = async (dataObj: any) => {
 
  const url = `${Config.baseUrl}/transaction/`;
  try {
    const result: any = await axios.post(url, dataObj, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await addMegaTransaction(dataObj)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

//Edit Transaction
const EditTransaction = async (dataObj: any) => {
  const url = `${Config.baseUrl}/transaction/update`;
  try {
    const result: any = await axios.post(url, dataObj, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await EditTransaction(dataObj)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param bankName
 *
 */
const getFlow = async (email: string) => {
  const url = `${Config.baseUrl}/mastertxn/flow-txn?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getFlow(email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

//Edit Transaction
const uploadTransaction = async (email: string, dataObj: any,folio_approval:string) => {
  const url = `${Config.baseUrl}/transaction/bulk-upload`;
  try {
    let data = JSON.stringify(dataObj);
    const result: any = await axios.post(
      url,
      { email, data,folio_approval },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await uploadTransaction(email, dataObj,folio_approval)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const uploadFundDividendPayoutTransaction = async (email: string, dataObj: any, amc_code: any,fund_code: any,fund_account:any,account_title: any,account_no:any, bank:any, branch: any ) => {
  const url = `${Config.baseUrl}/transaction/dividend-bulk-upload`;
  try{
  let data = JSON.stringify(dataObj);
  const result: any = await axios.post(
    url,
    { email, data, amc_code, fund_code, fund_account, account_title, account_no, bank, branch },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { //unauthorized
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
        // let data = JSON.stringify(dataObj);
       return await uploadFundDividendPayoutTransaction(email, dataObj, amc_code, fund_code, fund_account,account_title, account_no, bank, branch)
      }else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
   
  }
};

const addConversionOfUnitsTransaction = async (email: string, transactionData: any) => {
  const url = `${Config.baseUrl}/transaction/conversion-of-units`;
  try{
  const result: any = await axios.post(
    url,
    { email, transactionData},
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { //unauthorized
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
        // let data = JSON.stringify(dataObj);
       return await addConversionOfUnitsTransaction(email, transactionData)
      }else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
   
  }
};

export { addMegaTransaction, EditTransaction, getFlow, uploadTransaction,uploadFundDividendPayoutTransaction, addConversionOfUnitsTransaction };
