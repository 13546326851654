/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "./containers/DashboardPage";
import Icons from "./views/Icons.js";
import Map from "./views/Map.js";
import Notifications from "./views/Notifications.js";
import Rtl from "./views/Rtl.js";
import TableList from "./views/TableList.js";
import Typography from "./views/Typography.js";
import UserProfile from "./views/UserProfile.js";
import Transaction from "./containers/transaction-listing";
import ConcernedOfficer from "./containers/officer-listing";
import AuthorizerListing from "./containers/authorizer-listing";

import CreateTransaction from "./containers/beta-txn-type";
import RejectedTransaction from "./containers/rejected-transactions";
import NAV from "./containers/nav";
import ClientList from "./containers/unit-holder/client-listing";
import ViewClient from "./containers/unit-holder/view-client";
import PendingTransactions from "./containers/pending-transactions";
import ComplianceSignatoryTransactions from "./containers/comp-sign-listing";
import SignatoryA from "./containers/signatory-a";
import NotificationScreen from './containers/notification-screen';
import UserSetting from './containers/user-setting'	

// reports
import BankStatement from "./containers/reports/bank-statement";
import RecieptPaymentRegisterReport from "./containers/reports/reciept-payment-register-report";
import UnitHolderStatement from "./containers/reports/unit-holder-statement";
import TransactionReportScreen from "./containers/reports/transaction-report";
let routes = [

  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
  },
  
  {
    path: "/transaction",
    name: "Transaction",
    rtlName: "الرموز",
    icon: "tim-icons icon-bullet-list-67",
    component: PendingTransactions,
    layout: "/admin",
  },

  {
    collapse: true,
    name: "Transaction Creator",
    rtlName: "خرائط",
    icon: "tim-icons icon-credit-card",
    state: "transactionCollapse",
    views: [
      {
        path: "/create-transaction",
        name: "Create Transaction",
        rtlName: "خرائط جوجل",
        mini: "TC",
        rtlMini: "ووم",
        component: CreateTransaction,
        layout: "/admin",
      },
      {
        path: "/rejected-transaction",
        name: "Rejected Transactions",
        rtlName: "خريطة كاملة الشاشة",
        mini: "RT",
        rtlMini: "ووم",
        component: RejectedTransaction,
        layout: "/admin",
      },
      {
        path: "/transaction-listing",
        name: "Transaction Listing",
        rtlName: "خريطة كاملة الشاشة",
        mini: "TL",
        rtlMini: "ووم",
        component: Transaction,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Daily Tasks",
    rtlName: "خرائط",
    icon: "tim-icons icon-pin",
    state: "tasksCollapse",
    views: [
      {
        path: "/nav-update",
        name: "NAV Update",
        rtlName: "خرائط جوجل",
        mini: "NU",
        rtlMini: "زم",
        component: NAV,
        layout: "/admin",
      },

    ],
  },

  {
    collapse: true,
    name: "Reports",
    rtlName: "خرائط",
    icon: "tim-icons icon-single-02 Reports",
    state: "reportsCollapse",
    views: [
      {
        path: "/bank-statement",
        name: "Bank Statement",
        rtlName: "الرموز",
        mini: "BS",
        rtlMini: "زم",
        component: BankStatement,
        layout: "/admin",
      },
      {
        path: "/receipt-payment-register-report",
        name: "Receipt Payment Register Report",
        rtlName: "خريطة كاملة الشاشة",
        mini: "RPRR",
        rtlMini: "ووم",
        component: RecieptPaymentRegisterReport,
        layout: "/admin",
      },
      {
        path: "/transaction-report",
        name: "Transaction Report",
        rtlName: "خريطة كاملة الشاشة",
        mini: "TR",
        rtlMini: "ووم",
        component: TransactionReportScreen,
        layout: "/admin",
      },
      {
        path: "/unit-holder-statement",
        name: "Unit Holder Statement",
        rtlName: "خريطة كاملة الشاشة",
        mini: "UHS",
        rtlMini: "ووم",
        component: UnitHolderStatement,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    name: "Authorized User",
    rtlName: "خرائط",
    icon: "tim-icons icon-single-02",
    state: "mapsCollapse",
    views: [
      {
        path: "/concerned-officer",
        name: "Concerned Officers",
        rtlName: "الرموز",
        mini: "CO",
        rtlMini: "زم",
        component: ConcernedOfficer,
        layout: "/admin",
      },
      {
        path: "/transaction-creators",
        name: "Transaction Creators",
        rtlName: "خريطة كاملة الشاشة",
        mini: "TC",
        rtlMini: "ووم",
        component: AuthorizerListing,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Management",
    rtlName: "خرائط",
    icon: "tim-icons icon-settings-gear-63",
    state: "managementCollapse",
    views: [
      {
        path: "/unit-holders",
        name: "Unit Holders",
        rtlName: "خرائط جوجل",
        mini: "UH",
        rtlMini: "زم",
        component: ClientList,
        layout: "/admin",
      },

    ],
  },
  
];


export default routes;
