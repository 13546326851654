import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 *
 * @param email
 * @param broker_name
 * @param broker_type
 * @param focal_email
 * @param ntn
 * @param stn
 * @param bank_name
 * @param branch_name
 * @param iban
 *
 */
const addBroker = async (
  email: string,
  company_name: string,
  focal_email: string,
  ntn: string,
  stn: string,
  bank_name: string,
  branch_name: string,
  iban: string,
  code: string,
  account_title: string
) => {
  const url = `${Config.baseUrl}/broker/`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        company_name,
        focal_email,
        ntn,
        stn,
        bank_name,
        branch_name,
        iban,
        code,
        account_title,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await addBroker(email, company_name, focal_email, ntn, stn, bank_name, branch_name, iban, code, account_title)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 * @param email
 */
const getAllBrokers = async (
  email: string,
  page_number: string = '',
  page_size: string = ''
) => {
  const url = `${Config.baseUrl}/broker?email=${email}&page_number=${page_number}&page_size=${page_size}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAllBrokers(email, page_number, page_size)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param broker_name
 * @param broker_type
 * @param focal_email
 * @param ntn
 * @param stn
 * @param bank_name
 * @param branch_name
 * @param iban
 *
 */
const getBroker = async (email: string, code: string = '') => {
  const url = `${Config.baseUrl}/broker/get-by-code?email=${email}&code=${code}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getBroker(email, code)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param company_name
 * @param focal_email
 * @param ntn
 * @param stn
 * @param bank_name
 * @param branch_name
 * @param iban
 *
 */
const updateBroker = async (
  email: string,
  company_name: string,
  focal_email: string,
  ntn: string,
  stn: string,
  bank_name: string,
  branch_name: string,
  iban: string,
  code: string,
  account_title: string
) => {
  const url = `${Config.baseUrl}/broker/update/`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        company_name,
        focal_email,
        ntn,
        stn,
        bank_name,
        branch_name,
        iban,
        code,
        account_title,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await updateBroker(email, company_name, focal_email, ntn, stn, bank_name, branch_name, iban, code, account_title)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param broker_name
 * @param broker_type
 * @param focal_email
 * @param ntn
 * @param stn
 * @param bank_name
 * @param branch_name
 * @param iban
 *
 */
const updateBrokerToAmc = async (
  email: string,
  amc_code: string,
  brokers: string
) => {
  const url = `${Config.baseUrl}/amc/update?email=${email}&amc_code=${amc_code}&brokers=${brokers}`;
  try {
    const result: any = await axios.post(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await updateBrokerToAmc(email, amc_code, brokers)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const getBrokerType = async (email: string) => {
  const url = `${Config.baseUrl}/mastertxn/company-names?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getBrokerType(email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const getBrokerByType = async (email: string, company_type: string = '') => {
  const url = `${Config.baseUrl}/broker?email=${email}&company_type=${company_type}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getBrokerByType(email, company_type)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }

  }
};

export {
  getBrokerByType,
  addBroker,
  getBrokerType,
  getAllBrokers,
  getBroker,
  updateBroker,
  updateBrokerToAmc,
};
