
import React from 'react';
import { useRef, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
} from 'reactstrap';

import Particles from 'react-particles-js';

import { generateTwoFa, getLoggedinUserInfo, login, twoFALogin } from './../../stores/services/auth.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory } from 'react-router-dom';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import {
  getCaptype,
  getTxnCatagory,
} from './../../stores/services/transactions.service';
import { getAmcByCode } from './../../stores/services/amc.service';
import useNetwork from '../network';
import ReCAPTCHA from "react-google-recaptcha";
import { env } from "../../env";
import Auth from '../../Auth';
import { getFeatures } from '../../stores/services/role.service';
import './Calculator.css';

const Login = () => {
  let first_input: HTMLInputElement | null = null;
  let second_input: HTMLInputElement | null = null;
  let third_input: HTMLInputElement | null = null;
  let fourth_input: HTMLInputElement | null = null;
  let fifth_input: HTMLInputElement | null = null;
  let sixth_input: HTMLInputElement | null = null;
  const isConnected = useNetwork();
  const history = useHistory();
  const [popup, setPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [Loading, setLoading] = useState(false);
  const [twoFACode, setTwoFACode] = useState('');
  const [firstCode, setFirstCode] = useState('');
  const [secondCode, setSecondCode] = useState('');
  const [thirdCode, setThirdCode] = useState('');
  const [fourthCode, setFourthCode] = useState('');
  const [fifthCode, setFifthCode] = useState('');
  const [sixthCode, setSixthCode] = useState('');
  const [inputType, setInputType] = useState('password');
  const emailRegex = new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$');
  const [capTaxTypes, setCapTaxTypes] = useState('');
  const [txnCategory, setTxnCategory] = useState('');
  const [allFeatures, setAllFeatures] = useState('');

  const [loginEnable, setLoginEnable] = useState(false);

  React.useEffect(() => {
    if (env.CAPTCHA_ENABLED.toString() == 'false') {
      setLoginEnable(true);
    }
    document.title = `${env.REACT_APP_TITLE}`
  }, []);

  const handleClick = () => {
    inputType === 'text' ? setInputType('password') : setInputType('text');
  };
  const validate = async () => {
    let emailErr,
      passErr = '';
    email.trim() === ''
      ? (emailErr = 'Email Required')
      : emailRegex.test(email.toLowerCase()) !== true
        ? (emailErr = 'Invalid Email')
        : (emailErr = '');
    password.trim() === '' ? (passErr = 'Password Required') : (passErr = '');
    if (emailErr || passErr) {
      setEmailError(emailErr);
      setPasswordError(passErr);
      return false;
    } else {
      return true;
    }
  };

  function onChange(value) {
    const isValid = validate();
    setLoginEnable(true);
  }

  const loginBtn = async () => {
    const isValid = await validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await login(email.toLowerCase(), password);
        if (response.data.status == 200) {
          sessionStorage.setItem('token', response.data.token);
          sessionStorage.setItem('refreshToken', response.data.refreshToken);
            if (response.data.data?.two_fa_enabled && response.data.data?.two_fa_enabled === 'true') {
              
              toast.success(response.data.message)

              setPopup(true);
              setLoading(false);
            } else {
              try {
                const userInfo = await getLoggedinUserInfo(email.toLowerCase());
                if(userInfo.data.status==200){
                toast.success(userInfo.data.message);

                sessionStorage.setItem('email', userInfo.data.data.email.toLowerCase());
                sessionStorage.setItem('role', userInfo.data.data.role);
                sessionStorage.setItem('additional_features', userInfo.data.data.additional_access);
                sessionStorage.setItem('denied_features', userInfo.data.data.denied_access);
                sessionStorage.setItem('features', JSON.parse(userInfo.data.data.features));
                // sessionStorage.setItem('token', response.data.data.accessToken);
                // sessionStorage.setItem('refreshToken', response.data.data.refresh_token);
                sessionStorage.setItem('amc_code', userInfo.data.data.amc_code);
                sessionStorage.setItem('loggedInUser', JSON.stringify(userInfo.data.data));
                try {
                  const amcResponse = await getAmcByCode(email, userInfo.data.data.amc_code);
                  sessionStorage.setItem('amc_name', amcResponse.data.data[0].name);
                } catch (error) { }
                try {
                  const response = await getCaptype(email.toLowerCase());
                  sessionStorage.setItem('txnType', JSON.stringify(response.data.txntypes));
                } catch (error) { }
                try {
                  const response = await getTxnCatagory(email.toLowerCase());
                  sessionStorage.setItem('txnTypeCatagory', JSON.stringify(response.data.txn_catagory));
                } catch (error) { }
                try {
                  const response = await getFeatures(email.toLowerCase());
                  sessionStorage.setItem('allFeatures', JSON.stringify(response.data.features));
                } catch (error) { }
                setLoading(false);
                Auth.authenticate();
                sessionStorage.setItem('isAuthenticated', 'true')
                if (userInfo.data.data.role === 'AUTHORIZER-A') {
                  history.replace('/admin/home');
                } else if (userInfo.data.data.role === 'AUTHORIZER-B') {
                  history.replace('/admin/home');
                } else {
                  history.replace("/admin/home");
                }
              }else{
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('refreshToken');
                toast.error(userInfo.data.message);
              }
              } catch (error: any) {
                if(error.response!=undefined){
                toast.error(error.response.data.message);
                }else{
                  toast.error(error.message);
                }
              }
            }

        } else {
          toast.error(response.data.message);
        }
      } catch (err: any) {
        // toast.error(error.response.data.message);
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      setLoading(false);
    }
  };

  const login2FaBtn = async () => {
    setLoading(true);
    if (!firstCode) {
      setLoading(false);
      return;
    }
    if (!secondCode) {
      setLoading(false);
      return;
    }
    if (!thirdCode) {
      setLoading(false);
      return;
    }
    if (!fourthCode) {
      setLoading(false);
      return;
    }
    if (!fifthCode) {
      setLoading(false);
      return;
    }
    if (!sixthCode) {
      setLoading(false);
      return;
    }
    setTwoFACode(`${firstCode}${secondCode}${thirdCode}${fourthCode}${fifthCode}${sixthCode}`);
    try {
      const response = await twoFALogin(email.toLowerCase(), `${firstCode}${secondCode}${thirdCode}${fourthCode}${fifthCode}${sixthCode}`);
      if (response.data.status == 200) {
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('refreshToken', response.data.refreshToken);
        setPopup(false);
        setLoading(false);

        toast.success(response.data.message);
        try {
          const userInfo = await getLoggedinUserInfo(email.toLowerCase());
          if(userInfo.data.status==200){
          try {
            const amcResponse = await getAmcByCode(email, userInfo.data.data.amc_code);
            sessionStorage.setItem('amc_name', amcResponse.data.data[0].name);
          } catch (error) { }
          try {
            const response = await getCaptype(email.toLowerCase());
            sessionStorage.setItem('txnType', JSON.stringify(response.data.txntypes));
          } catch (error) { }
          try {
            const response = await getTxnCatagory(email.toLowerCase());
            sessionStorage.setItem('txnTypeCatagory', JSON.stringify(response.data.txn_catagory));
          } catch (error) { }
          try {
            const response = await getFeatures(email.toLowerCase());
            sessionStorage.setItem('allFeatures', JSON.stringify(response.data.features));
          } catch (error) { }

          sessionStorage.setItem('email', email.toLowerCase());
          sessionStorage.setItem('role', userInfo.data.data.role);
          sessionStorage.setItem('additional_features', userInfo.data.data.additional_access);
          sessionStorage.setItem('denied_features', userInfo.data.data.denied_access);
          sessionStorage.setItem('features', JSON.parse(userInfo.data.data.features));
          // sessionStorage.setItem('token', response.data.data.accessToken);
          // sessionStorage.setItem('refreshToken', response.data.data.refresh_token);
          sessionStorage.setItem('amc_code', userInfo.data.data.amc_code);
          sessionStorage.setItem('loggedInUser', JSON.stringify(userInfo.data.data));
          Auth.authenticate();
          sessionStorage.setItem('isAuthenticated', 'true')
          if (userInfo.data.data.role === 'AUTHORIZER-A') {
            history.replace('/admin/home');
          } else if (userInfo.data.data.role === 'AUTHORIZER-B') {
            history.replace('/admin/home');
          } else {
            history.replace("/admin/home");
          }
          }else{
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('refreshToken');
            toast.error(userInfo.data.message);
          }
        } catch (error: any) {

        }
      } else {
        setLoading(false);
        toast.error(response.data.message)
      }
    } catch (error: any) {
      setLoading(false);
      if(error.response!=undefined){
        toast.error(error.response.data.message);
      }else{
        toast.error(error.message);
      }
      
    }
    setFirstCode('');
    setSecondCode('');
    setThirdCode('');
    setFourthCode('');
    setFifthCode('');
    setSixthCode('');
    // twoFALogin(
    //   email.toLowerCase(),
    //   `${firstCode}${secondCode}${thirdCode}${fourthCode}${fifthCode}${sixthCode}`
    // )
    //   .then((response) => {
    //     setLoading(false);
    //     toast.success(response.message);

    //     // sessionStorage.setItem('txnType',capTaxTypes);
    //     // sessionStorage.setItem('txnTypeCatagory', txnCategory);
    //     // sessionStorage.setItem('allFeatures', allFeatures);

    //     sessionStorage.setItem('email', email.toLowerCase());
    //     sessionStorage.setItem('role', response.data.data.role);
    //     sessionStorage.setItem('additional_features', response.data.data.additional_access);
    //     sessionStorage.setItem('denied_features', response.data.data.denied_access);
    //     sessionStorage.setItem('features', JSON.parse(response.data.data.features));
    //     // sessionStorage.setItem('token', response.data.data.accessToken);
    //     // sessionStorage.setItem('refreshToken', response.data.data.refresh_token);
    //     sessionStorage.setItem('amc_code', response.data.data.amc_code);
    //     sessionStorage.setItem('loggedInUser', JSON.stringify(response.data.data));
    //     Auth.authenticate();
    //     sessionStorage.setItem('isAuthenticated', 'true')
    //     // if (response.data.data.role === 'AUTHORIZER-A') {
    //     //   history.replace('/admin/comp-sign-transactions/authorizer-a');
    //     // } else if (response.data.data.role === 'AUTHORIZER-B') {
    //     //   history.replace('/admin/comp-sign-transactions/authorizer-b');
    //     // } else {
    //     //   history.replace('/admin/dashboard');
    //     // }
    //     if (response.data.data.role === 'AUTHORIZER-A') {
    //       history.replace('/admin/home');
    //     } else if (response.data.data.role === 'AUTHORIZER-B') {
    //       history.replace('/admin/home');
    //     } else {
    //       history.replace("/admin/home");
    //     }
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     toast.error(err.message);
    //   });
  };
  return (
    <>
      {/* particles */}
      <Particles params={{
        particles: {
          color: {
            value: "#1F8EF1"
          },
          lineLinked: {
            color: {
              value: "#1F8EF1"
            },
            "enable": true,
            "distance": 150,
            "opacity": 0.4,
            "width": 1
          },
          number: {
            value: 150
          },
          size: {
            value: 5
          },

          "move": {
            "enable": true,
            "speed": 2,
            "direction": "none",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
              "enable": false,
              "rotateX": 600,
              "rotateY": 1200
            }
          }

        },

        "interactivity": {
          "detect_on": "canvas",
          "events": {
            "onhover": {
              "enable": true,
              "mode": "grab"
            },
            "onclick": {
              "enable": true,
              "mode": "push"
            },
            "resize": true
          },

          "modes": {
            "grab": {
              "distance": 150,
              "line_linked": {
                "opacity": 1
              }
            },
            "bubble": {
              "distance": 400,
              "size": 40,
              "duration": 2,
              "opacity": 8,
            },
            "repulse": {
              "distance": 200,
              "duration": 0.4
            },
            "push": {
              "particles_nb": 4
            },
            "remove": {
              "particles_nb": 2
            }
          }
        }
      }
      } />
      {!isConnected && <div className='network-box'>You're Offline</div>}
      <div className="container-login" >

        <ToastContainer />

        <div className={`fa-popup   ${popup ? '' : 'hidden'}`}>
          <div className="popup card-login card-white card mb-0">
            <p className=" text-black"> <strong>Two-Factor Authentication </strong>  </p>
            <p className="text-black">Please enter One-Time Password (OTP)</p>
            <div className="digit-holder mx-auto ">
              <FormGroup>
                <input
                  type="text"
                  className="form-control  "
                  ref={(input) => {
                    first_input = input;
                  }}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setFirstCode(e.target.value);
                      second_input?.focus();
                    }
                    return;
                  }}
                />
              </FormGroup>
              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    second_input = input;
                  }}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setSecondCode(e.target.value);
                      third_input?.focus();
                    }
                    return;
                  }}
                />
              </FormGroup>
              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    third_input = input;
                  }}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setThirdCode(e.target.value);
                      fourth_input?.focus();
                    }
                    return;
                  }}
                />
              </FormGroup>
              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    fourth_input = input;
                  }}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setFourthCode(e.target.value);
                      fifth_input?.focus();
                    }
                    return;
                  }}
                />
              </FormGroup>

              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    fifth_input = input;
                  }}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setFifthCode(e.target.value);
                      sixth_input?.focus();
                    }
                    return;
                  }}
                />
              </FormGroup>
              <FormGroup>
                <input
                  type="text"
                  className="form-control"
                  ref={(input) => {
                    sixth_input = input;
                  }}
                  maxLength={1}
                  onChange={(e) => {
                    if (e.target.value.length === 1) {
                      setSixthCode(e.target.value);
                    }
                    return;
                  }}
                />
              </FormGroup>
            </div>
            <div className="btn-holder">
              <button
                className="btn btn-primary bg-positive"
                onClick={login2FaBtn}
                disabled={Boolean(Loading)}
              >
                {Loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Confirm</span>
                )}
              </button>
              <button
                className="btn btn-default bg-negative"
                onClick={() => {
                  setPopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        <Col className="ml-auto mr-auto" lg="4" md="6">
          <Card className="card-login card-white bg-transparent  ">
            <CardHeader className="d-flex justify-content-center mt-3"  >
              <img
                alt=""
                src={require("../../assets/MACS-FO-Logo.png").default} width={155}
              />
              <br />
            </CardHeader>
            <CardBody >
              <div className="mt-2">
                <label>Email</label>
                <FormGroup>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError('');
                    }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        // loginBtn();
                      }
                    }}
                  />
                  {emailError ? (
                    <p className="error-labels login-err">{emailError}</p>
                  ) : (
                    ''
                  )}
                </FormGroup>
              </div>

              <div className="mt-2">
                <label>Password</label>
                <FormGroup>
                  <input
                    type={inputType}
                    className="form-control"
                    placeholder="Enter Your Password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError('');
                    }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        // loginBtn();
                      }
                    }}
                  />
                  {inputType === 'text' ? (
                    <MdVisibility
                      className="visibility-icon"
                      onClick={() => handleClick()}
                    />
                  ) : (
                    <MdVisibilityOff
                      className="visibility-icon"
                      onClick={() => handleClick()}
                    />
                  )}
                  {passwordError ? (
                    <p className="error-labels pass-err">
                      {passwordError}
                    </p>
                  ) : (
                    ''
                  )}
                </FormGroup>
              </div>

              <div>
                {env.CAPTCHA_ENABLED.toString() == 'true' && (
                  <ReCAPTCHA
                    sitekey="6Le0HoAdAAAAADxrbEVg8ksdFb8AQMLROtEQu2go"
                    onChange={onChange}
                    onExpired={(e) => {
                      setLoginEnable(false);
                    }}
                  />
                )}
              </div>

            </CardBody>

            <CardFooter className="d-flex space-between">
              {loginEnable === true && (
                <button
                  className="btn btn-primary"
                  onClick={loginBtn}
                  disabled={Boolean(Loading)}
                >
                  {Loading ? (
                    <>
                      <span
                        className="   fa fa-spinner fa-spin fa-1x "
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {/* <span className="login-txt"> Loading...</span> */}
                    </>
                  ) : (
                    <span className="login-text">Login</span>
                  )}
                </button>
              )}
              <Link to="/forgot-password" className="mt-2" replace>
                Forgot Password?
              </Link>
            </CardFooter>

          </Card>
          <div className='text-center'>{env.REACT_APP_TITLE} <br /> {env.REACT_APP_VERSION}</div>
        </Col>

      </div>

    </>
  );
};

export default Login;

