import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 * @param email
 */
const getChecklist = async (email: string, role_name: string = '') => {
  const url = `${Config.baseUrl}/checklist?email=${email}&role_name=${role_name}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getChecklist(email, role_name)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};
const editChecklistData = async (
  email: string,
  serial_no: string,
  message: string
) => {
  const url = `${Config.baseUrl}/checklist/update`;
  try {
    const result: any = await axios.post(
      url,
      { email, serial_no, message },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await editChecklistData(email, serial_no, message)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

export { getChecklist, editChecklistData };
