import axios from 'axios';
import Config from '../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 *
 * @param email
 * @param password
 */
 const login = async (email: string, password: string) => {
  const url = `${Config.loginRegisterUrl}/user/login`;
  // const url = `${Config.loginRegisterUrl}/user/login-amc`;
  // try{
  const result: any = await axios.post(url, {
    email,
    password,
    // user_type: 'AMC',
  });
  return result;
// }catch(err:any) {
//   let responseToHandle = err.response.data
//   let result = await RefreshTokenHandler.handleIt(responseToHandle)
//   if(result.status) {
//    return  login(email,password)
//   }else {
//     return result
//   }
// }
};

const getLoggedinUserInfo = async (email:string) => {
  // const url = `${Config.baseUrl}/user/get-logged-in-user-Info?email=${email}`;
  const url = `${Config.baseUrl}/user/get-logged-in-user-info-amc?email=${email}`;
  try{
    let result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    })
  return result;
}catch(err:any) {
  return err.response
  // let responseToHandle = err.response.data
  // let result = await RefreshTokenHandler.handleIt(responseToHandle)
  // if(result.status) {
  //  return  getLoggedinUserInfo();
  // }else {
  //   return result
  // }
}
};

/**
 *
 * @param email
 */
const forgotPassword = async (email: string) => {
  const url = `${Config.loginRegisterUrl}/user/forget-password`;
  try {
    const result: any = await axios.post(
      url,
      { email },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await forgotPassword(email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param otp
 */
const forgotPasswordOtp = async (email: string, otp: string, new_password: string, confirm_new_password: string) => {
  const url = `${Config.loginRegisterUrl}/user/verify-otp`;
  try {
    const result: any = await axios.post(url, { email, otp, new_password, confirm_new_password });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await forgotPasswordOtp(email, otp, new_password, confirm_new_password)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param two_fa_code
 */
 const twoFALogin = async (email: string, two_fa_code: string) => {
  const url = `${Config.loginRegisterUrl}/user/login/2fa`;
  try{
  const result: any = await axios.post(
    url,
    { email, two_fa_code },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await twoFALogin(email,two_fa_code)
      }else {
        return result
      }
    }else {
          throw err
    }
  
  }
};


/**
 *
 * @param email
 */
const getTwoFACode = async (email: string) => {
  const url = `${Config.baseUrl}/user/get-2fa-code`;
  try {
    const result: any = await axios.post(
      url,
      { email },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getTwoFACode(email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param two_fa_code
 * @param two_fa_enabled
 */
const enableDisableTwoFA = async (
  email: string,
  two_fa_code: string,
  two_fa_enabled: string
) => {
  const url = `${Config.baseUrl}/user/enable-disable-2fa`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        two_fa_code,
        two_fa_enabled,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await enableDisableTwoFA(email, two_fa_code, two_fa_enabled)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param status
 */
const updateUserStatus = async (email: string, status: string) => {
  const url = `${Config.baseUrl}/user/update-status`;
  try {
    const result: any = await axios.post(
      url,
      { email, status },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await updateUserStatus(email, status)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 */
const getUserInfo = async (email: string) => {
  const url = `${Config.baseUrl}/user/get-user-info`;
  try {
    const result: any = await axios.post(
      url,
      { email },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getUserInfo(email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const generateTwoFa= async (email: string) => {
  const url = `${Config.loginRegisterUrl}/user/generate-2fa`;
  try{
  const result: any = await axios.post(url, { email });
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await generateTwoFa(email)
      }else {
        return result
      }
    }else {
          throw err
    }

  }
};

const logoutCentral = async (email) => {
  let token = sessionStorage.getItem('token')
  const url = `${Config.loginRegisterUrl}/user/logout`;
  const result = await axios.post(url, {
    email,
    token,
    // user_type: "ADMIN",
  },
  {
    headers: {
      Authorization: sessionStorage.getItem("token") || "",
    },
  });
  return result;
};

const logoutMain = async (email) => {
  let token = sessionStorage.getItem('token')
  const url = `${Config.baseUrl}/user/logout`;
  const result = await axios.post(url, {
    email,
    token,
    // user_type: "ADMIN",
  },
  {
    headers: {
      Authorization: sessionStorage.getItem("token") || "",
    },
  });
  return result;
};

export {
  login,
  getLoggedinUserInfo,
  forgotPassword,
  twoFALogin,
  getTwoFACode,
  enableDisableTwoFA,
  updateUserStatus,
  getUserInfo,
  forgotPasswordOtp,
  generateTwoFa,
  logoutMain,
  logoutCentral
};
