import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Input, FormGroup } from 'reactstrap';

export default function CheckListItem({
  updateFormData,
  val,
  ind,
}): JSX.Element {
  const [checked, setChecked] = useState('');

  const handleCheckbox = (status) => {
    setChecked(status);
    val.value = status;
    updateFormData(val, ind);
  };

  const handleCommentChange = (text) => {
    val.comment = text;
    updateFormData(val, ind);
  };

  return (
    <div>
      <Row key={ind} className="pt-3"> 
         <Col md="3 ">
             <text className="text-primary"> {val.serial_no}.</text>  <text>{val.message}</text>
        </Col>
        <Col md="8">
          <div>
            <form action="">
              <div className="row">
                <div className="col-sm-3">         
                    <FormGroup check>
                        <Label check>
                          <Input type="checkbox"
                          checked={checked == 'compliant'} 
                          value="compliant"
                          id="complaint"
                          onChange={(e) => handleCheckbox(e.target.value)}
                           />
                          <span className="form-check-sign" />
                          Compliant
                        </Label>
                      </FormGroup>
              </div>
              <div className="col-sm-3">
                 <FormGroup check>
                        <Label check>
                          <Input type="checkbox"
                          id="unlisted"
                          checked={checked == 'na'}
                          value="na"
                          onChange={(e) => {
                            handleCheckbox(e.target.value);
                          }} />
                          <span className="form-check-sign" />
                          N /A
                        </Label>
                      </FormGroup>
             </div>
             <div className="col-sm-6">
               <div className="form-group">
              <input
                type="text"
                placeholder="Comments"
                className="form-control"
                onChange={(e) => {
                  handleCommentChange(e.target.value);
                }}
              />
              </div>
              </div>
              </div>
            </form>
            
          </div>
        </Col>
      </Row>
    </div>
  );
}
