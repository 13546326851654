import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 *
 * @param name
 * @param code
 * @param email
 */
const addBank = async (name: string, code: string, email: string) => {
  const url = `${Config.baseUrl}/bank/`;
  try {
    const result: any = await axios.post(
      url,
      { name, code, email },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await addBank(name, code, email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }  
  }
};

/**
 * @param email
 */
const getAllBanks = async (email: string,  page_number: string = '',page_size: string = '') => {
  // const url = `${Config.baseUrl}/bank?email=${email}`;
  const url = `${Config.baseUrl}/bank?email=${email}&page_number=${page_number}&page_size=${page_size}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAllBanks(email,page_number,page_size)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const getCities = async () => {
  const email = sessionStorage.getItem('email') || '';
  const url = `${Config.baseUrl}/bank/cities?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getCities()
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};
/**
 * @param email
 * @param code
 */
const getBankByCode = async (email: string, code: string) => {
  const url = `${Config.baseUrl}/bank/get-by-code?email=${email}&code=${code}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getBankByCode(email, code)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param code
 * @param status
 */
const updateBankStatus = async (
  email: string,
  code: string,
  status: string
) => {
  const url = `${Config.baseUrl}/bank/update-status`;
  try {
    const result: any = await axios.post(
      url,
      { email, code, status },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await updateBankStatus(email, code, status)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

export { addBank, getAllBanks, getBankByCode, updateBankStatus, getCities };
