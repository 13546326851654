
import React from 'react';
// import Header from './../../components/Header';	
import { useState } from 'react';
import { getAllBanks, getCities } from './../../stores/services/bank.service';
import { getAmc } from './../../stores/services/amc.service';
import { getFunds } from './../../stores/services/funds.service';
import { getBranchByBankName } from './../../stores/services/branch.service';
import { getNatureOfTx } from './../../stores/services/transactions.service';
import { editUnitHolder } from './../../stores/services/unit-holder.service';
import ReactTooltip from 'react-tooltip';
import { getFundByAmc } from './../../stores/services/funds.service';
import { useHistory } from 'react-router-dom';
import { IoIosClose } from 'react-icons/io';
import { Container, Row, Col, FormGroup, Label, Input, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ToggleButton from 'react-toggle-button';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import InputMask from 'react-input-mask';
import { accountTitle_AccountNumber_Regex, numberWithCommas } from './../../utils/customFunction';
import { Modal } from 'react-bootstrap';

const EditClients = () => {
  const history = useHistory();
  //hooks for getting all inputs from user	
  const [amcName, setAmcName] = useState('');
  const [name, setName] = useState('');
  const [folioNo, setFolioNo] = useState('');
  const [cnic, setCnic] = useState('');
  const [cnicExpiry, setCnicExpiry] = useState('');
  const [ntn, setNtn] = useState('');
  const [mainFolioNo, setMainFolioNo] = useState('');
  const [taxStatus, setTaxStatus] = useState('');
  //	
  const [clientEmail, setClientEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [modalCity,setModalCity] = useState("")
  const [viewAddCity,setViewAddCity] = useState(false)
  // const [filer, setFiler] = useState(false)	;
  // const updateFiler = () => setFiler(!filer)
  const [province, setProvince] = useState('');
  //	
  const [accTitle, setAccTitle] = useState('');
  const [accNo, setAccNo] = useState('');
  const [bankname, setBankName] = useState('');
  const [branchName, setBranchName] = useState('');
  //	
  const [blnUpdate, setBlnUpdate] = useState<any>();
  const [reinvestCheck, setReinvestCheck] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  const amcCode = sessionStorage.getItem('amc_code') || '';
  const [fundLoading, setFundLoading] = useState<boolean>(false);
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  const [father, setFather] = useState('');
  const [tax_exempt, setTax_exempt] = useState(false);
  const [zakat_exempt, setZakat_exempt] = useState(false);
  const [type, setType] = useState('');
  // guardian
  const [guardian_name, setGuardian_name] = useState('');
  const [guardian_relation, setGuardian_relation] = useState('');
  const [guardian_cnic, setGuardian_cnic] = useState('');
  // nominee
  const [nominee_name, setNomineeName] = useState('');
  const [nominee_relation, setNomineeRelation] = useState('');
  const [nominee_cnic, setNomineeCnic] = useState('');
  // joint_holder One
  const [joint_h_1_name, setJointHolderOneName] = useState('');
  const [joint_h_1_father_spouse, setJointHolderOneFather] = useState('');
  const [joint_h_1_cnic, setJointHolderOneCnic] = useState('');
  const [joint_h_1_taxStatus, setJointHolderOneTaxStatus] = useState('');
  // joint_holder two
  const [joint_h_2_name, setJointHolderTwoName] = useState('');
  const [joint_h_2_father_spouse, setJointHolderTwoFather] = useState('');
  const [joint_h_2_cnic, setJointHolderTwoCnic] = useState('');
  const [joint_h_2_taxStatus, setJointHolderTwoTaxStatus] = useState('');
  // joint_holder Three
  const [joint_h_3_name, setJointHolderThreeName] = useState('');
  const [joint_h_3_father_spouse, setJointHolderThreeFather] = useState('');
  const [joint_h_3_cnic, setJointHolderThreeCnic] = useState('');
  const [joint_h_3_taxStatus, setJointHolderThreeTaxStatus] = useState('');

  const [portfolioManagement, setPortfolioManagement] = useState('');

  const [cdc_IAS, setCDCIAS] = useState('');
  const [cdc_subAccount, setCDCSubAccount] = useState('');
  const [cdc_participantID, setCDCParticipantID] = useState('');

  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('clientObj') || '');
    const fundData = JSON.parse(sessionStorage.getItem('fund_data') || '');	
    // if (obj.balance_unit) {	
    //   setTotalFunds(JSON.parse(obj.balance_unit));	
    // }	
    if (obj.balance_unit) {	
      let data = JSON.parse(obj.balance_unit).map((item,index) => {
        let fName;
         fundData.map((elem)=>{
        	  if(item.fund_code===elem.symbol_code){
        	    fName = elem.fund_name;
        	  }
        	})
          return{...item,fundName:fName}
      })
      setTotalFunds(data)	
    }	
    setAccTitle(obj.account_title);
    setAccNo(obj.account_no);
    setType(obj.type);
    setFather(obj.father_spouse);
    if (obj.tax_exempt === 'true') {
      setTax_exempt(true);
    } else {
      setTax_exempt(false);
    }
    if (obj.zakat_exempt === 'true') {
      setZakat_exempt(true);
    } else {
      setZakat_exempt(false);
    }
    setPhone(obj.phone);
    setCnicExpiry(obj.cnic_expiry);
    setClientEmail(obj.email);
    setMainFolioNo(obj.main_folio_no);
    setBankName(obj.bank_name);
    getBranchByName(obj.bank_name);
    setBranchName(obj.branch_name);
    setAmcName(obj.amc_code);
    setBlnUpdate(obj.balance_unit);
    setFolioNo(obj.folio_no.replace('UNITHOLDER_', ''));
    setCnic(obj.cnic);
    setName(obj.name);
    setNtn(obj.ntn);
    // setClientEmail(obj.client_email);	
    setMobile(obj.mobile);
    setGuardian_name(obj.guardian_name);
    setGuardian_relation(obj.guardian_relation);
    setGuardian_cnic(obj.guardian_cnic);
    setNomineeName(obj.nominee_name);
    setNomineeRelation(obj.nominee_relation);
    setNomineeCnic(obj.nominee_cnic);
    setJointHolderOneName(obj.joint_h_1_name);
    setJointHolderOneFather(obj.joint_h_1_father_spouse);
    setJointHolderOneCnic(obj.joint_h_1_cnic);
    setJointHolderTwoName(obj.joint_h_2_name);
    setJointHolderTwoFather(obj.joint_h_2_father_spouse);
    setJointHolderTwoCnic(obj.joint_h_2_cnic);
    setJointHolderThreeName(obj.joint_h_3_name);
    setJointHolderThreeFather(obj.joint_h_3_father_spouse);
    setJointHolderThreeCnic(obj.joint_h_3_cnic);
    setPortfolioManagement(obj.portfolio_management);
    obj.reinvest === 'true' ? setReinvestCheck(true) : setReinvestCheck(false);
    setAddress(obj.address);
    setCity(obj.city);
    // setFiler(obj.filer);
    // obj.filer === 'true' ? setFiler(true) : setFiler(false);	
    setProvince(obj.province);
    setTaxStatus(obj.tax_status);
    setJointHolderOneTaxStatus(obj.joint_h1_tax_status);
    setJointHolderTwoTaxStatus(obj.joint_h2_tax_status);
    setJointHolderThreeTaxStatus(obj.joint_h3_tax_status);
    setCDCIAS(obj.cdc_ias);
    setCDCSubAccount(obj.cdc_sub_account);
    setCDCParticipantID(obj.cdc_participant_id);
    // const fetchAmcFirst = async () => {	
    //   try {	
    //     const amcResponse = await getAmc(email);	
    //     setAmcdata(amcResponse.data.data);	
    //     amcResponse.data.data.map((amc: any) => {	
    //       if (amc.name === obj.amc_name) {	
    //         getfundByAmcCode(amc.amc_code);	
    //       }	
    //     });	
    //   } catch (error) {}	
    // };	
    // fetchAmcFirst();	
  }, []);
  const getfundByAmcCode = async (code: string) => {
    allFunds.length = 0;
    setAllFunds(allFunds);
    //get funds by amc for dropdown	
    try {
      const response = await getFundByAmc(email, amcCode);
      setAllFunds(response.data.data);
      if (response.data.data.length > 0) {
        setFundLoading(false);
      } else {
        setFundLoading(true);
      }
    } catch (error) { }
  };
  //error getting hooks	
  const [accTitleError, setAccTitleError] = useState('');
  const [accNoError, setAccNoError] = useState('');
  const [bankNameError, setBankNameError] = useState('');
  const [branchNameError, setBranchNameError] = useState('');
  const [amcNameError, setAmcError] = useState('');
  const [blnUpdateError, setBlnUpdateError] = useState('');
  const [folioNoError, setFolioNoError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [clientEmailError, setClientEmailError] = useState('');
  const [cnicExpiryError, setCnicExpiryError] = useState('');
  const [cnicError, setCnicError] = useState('');
  const [ntnError, setNtnError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [cityError, setCityError] = useState('');
  const [provinceError, setProvinceError] = useState('');
  const [nameError, setNameError] = useState('');
  const [clientNameError, setClientNameError] = useState('');
  const [investorError, setInvestorError] = useState('');
  const [Loading, setLoading] = useState(false);
  //for dropdown data	
  const [amcdata, setAmcdata] = useState<any>([]);
  const [bankNameData, setBankNameData] = useState<any>([]);
  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [allTxData, setAllTxData] = useState<any>([]);
  const [branchLoading, setBranchLoading] = useState<boolean>(false);
  const [allCitiesData, setAllCitiesData] = useState<any>([]);
  const [totalFunds, setTotalFunds] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);
  const getBranchByName = async (name: string) => {
    setBranchLoading(true);
    allbranchesData.length = 0;
    setAllBranchesData(allbranchesData);
    //get all branches for selected bank for dropdown	
    try {
      const response = await getBranchByBankName(email, name);
      setAllBranchesData(response.data.data);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) { }
    setBranchLoading(false);
  };
  React.useEffect(() => {
    const fetchAmc = async () => {
      bankNameData.length = 0;
      setBankNameData(bankNameData);
      //get all banks list for dropdown	
      try {
        const response = await getAllBanks(email);
        setBankNameData(response.data.data);
      } catch (error) { }
      //get tx nature data for dropdown	
      try {
        const response = await getCities();
        const obj = JSON.parse(sessionStorage.getItem('clientObj') || '')
        if(obj.city && response.data.cities.indexOf(obj.city) == -1)
        {
          setAllCitiesData([...response.data.cities,obj.city])


        }else{
        setAllCitiesData(response.data.cities);	
        }
      } catch (error) { }
    };
    fetchAmc();
  }, []);

  const borderRadiusStyle = { borderRadius: 2 };

  //render dropdown for bank name data	
  const renderBankNameDropdown = () => {
    return bankNameData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.bank_code.replace('BANK_', '')}>
          {item.bank_code.replace('BANK_', '')}-{item.bank_name}
        </option>
      );
    });
  };
  //render dropdown for branch name data	
  const renderBranchsDropdown = () => {
    return allbranchesData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.branch_code}>
          {item.branch_code}-{item.branch_name}
        </option>
      );
    });
  };
  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      return (
        <option key={index} value={item.fund_name}>
          {item.fund_name}
        </option>
      );
    });
  };
  const folioRegex = new RegExp('^[A-Z]{1,3}-[A-Z]{1,3}-[0-9]{1,8}$');
  const renderCitiesDropdown = () => {
    return allCitiesData.map((item: any) => {
      return (
        <option key={item} value={item}>
          {item}
        </option>
      );
    });
  };
  //processing	
  const validate = () => {
    let accTitleErr,
      nameErr,
      accNoErr,
      banknameErr,
      branchNameErr,
      amcErr,
      cnicExpErr,
      phoneErr = '';
    let folioErr,
      cnicErr,
      ntnErr,
      mobileErr,
      addressErr,
      cityErr = '',
      provinceErr,
      typeErr = '';
    accTitle.trim() === '' ? (accTitleErr = 'Required') : (accTitleErr = '');
    accNo.trim() === '' ? (accNoErr = 'Required') : (accNoErr = '');
    bankname.trim() === '' ? (banknameErr = 'Required') : (banknameErr = '');
    branchName.trim() === ''
      ? (branchNameErr = 'Required')
      : (branchNameErr = '');
    amcName.trim() === '' ? (amcErr = 'Required') : (amcErr = '');
    folioNo.trim() === ''
      ? (folioErr = 'Required')
      : (folioErr = '');
    type.trim() === '' ? (typeErr = 'Required') : (typeErr = '');
    // name.trim() === '' ? (nameErr = 'Required') : (nameErr = '');

    if (
      accTitleErr ||
      cnicExpErr ||
      phoneErr ||
      accNoErr ||
      nameErr ||
      branchNameErr ||
      amcErr ||
      folioErr ||
      cnicErr ||
      ntnErr ||
      mobileErr ||
      addressErr ||
      cityErr ||
      provinceErr ||
      typeErr
    ) {
      setAccTitleError(accTitleErr);
      setPhoneError(phoneErr);
      setCnicError(cnicExpErr);
      setAccNoError(accNoErr);
      setBankNameError(banknameErr);
      setBranchNameError(branchNameErr);
      setAmcError(amcErr);
      setFolioNoError(folioErr);
      setCnicError(cnicErr);
      // setClientNameError(nameErr);
      setInvestorError(typeErr);
      setNtnError(ntnErr);
      setMobileError(mobileErr);
      setAddressError(addressErr);
      setCityError(cityErr);
      setProvinceError(provinceErr);
      return false;
    } else {
      return true;
    }
  };
  const addClients = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await editUnitHolder(
          email,
          folioNo,
          accNo,
          bankname,
          blnUpdate,
          cnic,
          mobile,
          city,
          accTitle,
          branchName,
          amcName,
          ntn,
          address,
          name,
          mainFolioNo,
          phone,
          cnicExpiry,
          JSON.stringify(reinvestCheck),
          clientEmail,
          father,
          tax_exempt.toString(),
          zakat_exempt.toString(),
          type, guardian_name,
          guardian_relation,
          guardian_cnic,
          nominee_name,
          nominee_relation,
          nominee_cnic,
          joint_h_1_name,
          joint_h_1_father_spouse,
          joint_h_1_cnic,
          joint_h_2_name,
          joint_h_2_father_spouse,
          joint_h_2_cnic,
          joint_h_3_name,
          joint_h_3_father_spouse,
          joint_h_3_cnic,
          portfolioManagement,
          // JSON.stringify(filer),
          province,
          taxStatus,
          joint_h_1_taxStatus,
          joint_h_2_taxStatus,
          joint_h_2_taxStatus,
          cdc_IAS,
          cdc_subAccount,
          cdc_participantID
        );
        toast.success('Unit holder successfully added');
        setTimeout(() => {
          history.replace(`/admin/unit-holders`);
        }, 2000);
      } catch (error: any) {
        toast.error(error.response.data.message);
      }
      setLoading(false);
    }
  };
  //render Fund table data	
  const renderFundDataInTable = () => {
    return totalFunds.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>
            {`${items.fundName} (${items.fund_code})`}
          </td>
          <td className='text-right'>{numberWithCommas(parseFloat(items.fund_unit.toString().replaceAll(',','')).toFixed(4))}</td>
        </tr>
      );
    });
  };

  const renderModalAddCity = () => {
    switch (viewAddCity) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              // setViewMoneyMarketModal(false);
              // setPIBFields(false);
            }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewAddCity(false);
                  setModalCity("")
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <div>
                <h6 className="title title-up">Add City</h6>
              </div>
            </div>

            <div className="modal-body">
              <div className="  ml-3 mt-3 mb-3 mr-4">

                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                
                <div className="form-group">
                  <div>City*</div>
                  <input
                    type="text"
                    value={modalCity}
                    className={
                      'form-control w-100 '
                    }
                    onChange={(e) => {
                      setModalCity(e.target.value);
                    }}
                  />
                </div>

                <div className=" ">

                  <div className=" mt-3">
                    <button type="button" className="btn btn-primary btn-block"
                      onClick={() => {
                     
                        if (!modalCity?.trim()) {
                          toast.error('Kindly Enter City Name.')
                        } else {
                          setAllCitiesData([...allCitiesData,modalCity])
                          setViewAddCity(false);
                          setCity(modalCity)
                         
                          setModalCity('');
                        }
                      }}>Add</button>
                  </div>
                </div>

              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              {/* <Header />	 */}
              <div className="input-holder">
                <h4 className="card-title"><i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                  history.replace('/admin/unit-holders')
                }}></i>Edit - Unit Holder</h4>
                <Link
                  to="/admin/unit-holders"
                  className="t-3 ml-auto btn btn-primary btn-sm"
                  replace
                >
                  <i className="fa fa-eye mr-2"></i> View All
                </Link>
              </div>

              <Row>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Registration
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group  ">
                        <label>Folio No</label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${folioNoError ? 'required-border' : ''
                              }`}
                            value={folioNo}
                            readOnly
                            onChange={(e) => {
                              setFolioNo(e.target.value.toUpperCase());
                              setFolioNoError('');
                            }}
                          />
                          {/* {folioNoError ? <p className="error-labels error-message2">{folioNoError}</p> : ''} */}
                        </div>
                      </div>

                      <Row>
                        <Col md="6">
                          <div className="form-group">
                            <label>Investor Type<span className="color-amber">*</span></label>
                            <div>
                              <select
                                className={`form-control  w-100 ${investorError ? 'required-border' : ''
                                  }`}
                                value={type}
                                onChange={(e) => {
                                  setType(e.target.value);
                                  setInvestorError('');
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  Select Type
                                </option>
                                <option value="Individual">Individual</option>
                                <option value="Corporate">Corporate</option>
                              </select>
                            </div>
                          </div>

                        </Col>

                        <Col md="6">
                          <div className="form-group">
                            <label>Portfolio Management</label>
                            <div>
                              <select
                                className={`form-control  w-100`}
                                value={portfolioManagement}
                                onChange={(e) => {
                                  setPortfolioManagement(e.target.value);
                                }}

                              >
                                <option value="" defaultChecked hidden>
                                  Fund
                                </option>
                                <option value="fund">Fund</option>
                                <option value="sma">SMA</option>
                              </select>
                            </div>
                          </div>
                        </Col>
                      </Row>


                      <div className="form-group d-none">
                        <label>Main Folio No (Optional)</label>
                        <div  >
                          <input
                            type="text"
                            className="form-control"
                            value={mainFolioNo}
                            onChange={(e) => {
                              setMainFolioNo(e.target.value.toUpperCase());
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group">

                        <label>Tax Status</label>
                        <select
                          className="form-control"
                          value={taxStatus}
                          onChange={(e) => {
                            setTaxStatus(e.target.value)
                          }}>
                          <option value="" hidden> Select Tax Type</option>
                          <option value="Filer">Filer</option>
                          <option value="Non-Filer">Non-Filer</option>
                        </select>
                      </div>

                      <div className="d-flex justify-content-between mt-4 pb-3">

                        <div className="form-group d-flex">
                          <ToggleButton
                            value={tax_exempt}
                            thumbStyle={borderRadiusStyle}
                            trackStyle={borderRadiusStyle}
                            onToggle={() => {
                              if (tax_exempt) {
                                setTax_exempt(false);
                              } else {
                                setTax_exempt(true);
                              }
                            }}
                          />
                          <label className="mt-2 ml-2">Tax Exempt</label>
                        </div>


                        <div className="form-group d-flex">
                          <ToggleButton
                            value={zakat_exempt}
                            thumbStyle={borderRadiusStyle}
                            trackStyle={borderRadiusStyle}
                            onToggle={() => {
                              if (zakat_exempt) {
                                setZakat_exempt(false);
                              } else {
                                setZakat_exempt(true);
                              }
                            }}
                          />
                          <label className="ml-2 mt-2">Zakat Exempt</label>
                        </div>


                        <div>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox"
                                checked={reinvestCheck}
                                onChange={(e) => {
                                  setReinvestCheck(!reinvestCheck);
                                }} />
                              <span className="form-check-sign" />
                              Fund Dividend Reinvest
                            </Label>
                          </FormGroup>
                        </div>

                      </div>


                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Personal Details
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group  ">
                        <label>Client Name<span className="color-amber">*</span></label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${clientNameError ? 'required-border' : ''
                              }`}
                            value={name}
                            readOnly
                            onChange={(e) => {
                              setName(e.target.value);
                              // setClientNameError('');
                            }}
                          />
                          {/* {nameError ? <p className="error-labels error-message">{nameError}</p> : ''} */}
                        </div>
                      </div>
                      {type === 'Individual' && (
                        <div className="form-group">
                          <label>Father/Spouse Name</label>
                          <div className="">
                            <input
                              type="text"
                              className={'form-control  w-100'}
                              value={father}
                              onChange={(e) => {
                                setFather(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {type === 'Individual' && (
                        <Row>
                          <Col md="8">
                            <div className="form-group  ">
                              <label>CNIC</label>
                              <div  >
                                {/* <input	
                          type="text"	
                          className={`form-control  w-100 ${	
                            cnicError ? 'required-border' : ''	
                          }`}	
                          value={cnic}	
                          onChange={(e) => {	
                            setCnic(e.target.value);	
                            setCnicError('');	
                          }}	
                        />	 */}

                                <InputMask
                                  className={`form-control  w-100 ${cnicError ? 'required-border' : ''
                                    }`}
                                  mask="99999-9999999-9"
                                  value={cnic}
                                  readOnly
                                  onChange={(e) => {
                                    setCnic(e.target.value);
                                    setCnicError('');
                                  }}></InputMask>
                                {/* {cnicError ? <p className="error-labels error-message">{cnicError}</p> : ''} */}
                              </div>
                            </div>
                          </Col>

                          <Col md="4">
                            <div className="form-group  ">
                              <label>CNIC Expiry</label>
                              <div  >
                                <input
                                  type="date"
                                  className={`form-control  w-100 ${cnicExpiryError ? 'required-border' : ''
                                    }`}
                                  value={cnicExpiry}
                                  onChange={(e) => {
                                    setCnicExpiry(e.target.value);
                                    setCnicExpiryError('');
                                  }}
                                />
                                {/* {cnicExpiryError ? <p className="error-labels error-message2">{cnicExpiryError}</p> : ''} */}
                              </div>
                            </div>
                          </Col>

                        </Row>
                      )}

                      {type === 'Corporate' && (
                        <div className="form-group  ">
                          <label>NTN</label>
                          <div  >
                            <input
                              type="number"
                              className={`form-control  w-100 ${ntnError ? 'required-border' : ''
                                }`}
                              value={ntn}
                              readOnly
                              onChange={(e) => {
                                setNtn(e.target.value);
                                setNtnError('');
                              }}
                            />
                            {/* {ntnError ? <p className="error-labels error-message">{ntnError}</p> : ''} */}
                          </div>
                        </div>
                      )}

                      {/* <div className="form-group ">		
                        <label>Filer</label>		
                        <div  >	 */}
                      {/* <FormGroup check>
                          <Label check>	
                          <input		
                            type="checkbox"		
                            // className={`form-control  w-100 ${		
                            //   ntnError ? 'required-border' : ''		
                            // }`}		
                            checked={filer}		
                            onChange={updateFiler}		
                          />
                           <span className="form-check-sign" />
                           Income Tax Return Filer
                          </Label>
                          </FormGroup>		 */}
                      {/* {ntnError ? <p className="error-labels error-message2">{ntnError}</p> : ''} */}
                      {/* </div>		
                      </div> */}

                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Contact
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group  ">
                        <label>Email</label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${clientEmailError ? 'required-border' : ''
                              }`}
                            value={clientEmail}
                            onChange={(e) => {
                              setClientEmail(e.target.value);
                              setClientEmailError('');
                            }}
                          />
                          {/* {clientEmailError ? <p className="error-labels error-message2">{clientEmailError}</p> : ''} */}
                        </div>
                      </div>

                      <Row>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>Mobile</label>
                            <div  >
                              {/* <input	
                        type="number"	
                        className={`form-control  w-100 ${	
                          mobileError ? 'required-border' : ''	
                        }`}	
                        value={mobile}	
                        onChange={(e) => {	
                          setMobile(e.target.value);	
                          setMobileError('');	
                        }}	
                      />	 */}
                              <PhoneInput
                                specialLabel=""
                                inputClass={`form-control  w-100 ${mobileError ? 'required-border' : ''
                                  }`}
                                country={'pk'}
                                placeholder="+92 123-1234567"
                                value={mobile}
                                onChange={(e) => {
                                  setMobile(e);
                                  setMobileError('');
                                }}
                              />

                              {/* {mobileError ? <p className="error-labels error-message">{mobileError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>


                        <Col md="6">
                          <div className="form-group  ">
                            <label>Phone</label>
                            <div  >
                              {/* <input	
                        type="number"	
                        className={`form-control  w-100 ${	
                          phoneError ? 'required-border' : ''	
                        }`}	
                        value={phone}	
                        onChange={(e) => {	
                          setPhone(e.target.value);	
                          setPhoneError('');	
                        }}	
                      />	 */}
                              <PhoneInput
                                specialLabel=""
                                inputClass={`form-control  w-100 ${phoneError ? 'required-border' : ''
                                  }`}
                                country={'pk'}
                                placeholder="+92 123-1234567"
                                value={phone}
                                onChange={(e) => {
                                  setPhone(e);
                                  setPhoneError('');
                                }}
                              />
                              {/* {phoneError ? <p className="error-labels error-message2">{phoneError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group  ">
                        <label>Address</label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${addressError ? 'required-border' : ''
                              }`}
                            value={address}
                            onChange={(e) => {
                              setAddress(e.target.value);
                              setAddressError('');
                            }}
                          />
                          {/* {addressError ? <p className="error-labels error-message">{addressError}</p> : ''} */}
                        </div>
                      </div>

                      <Row>
                        <Col>
                          <div className="form-group  ">
                            <label>City</label>
                            <div  >
                              <select
                                className={`form-control  w-100 ${cityError ? 'required-border' : ''
                                  }`}
                                value={city}
                                onChange={(e) => {
                                  setCityError('');
                                  if(e.target.value == "Other")
                                  {
                                    setCity("")
                                    setViewAddCity(true)
        
                                  }else{
                                  setCity(e.target.value);
                                  }
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select City
                                </option>
                                {renderCitiesDropdown()}
                              </select>
                              {/* {cityError ? <p className="error-labels error-message2">{cityError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="form-group">
                            <label>Territories</label>
                            <div  >
                              <select
                                className={`form-control  w-100 ${provinceError ? 'required-border' : ''
                                  }`}
                                value={province}
                                onChange={(e) => {
                                  setProvince(e.target.value);
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  Select
                                </option>
                                <option value="Islamabad">Islamabad</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Khyber Pakhtunkhwa">Khyber Pakhtunkhwa</option>
                                <option value="Balochistan">Balochistan</option>
                                <option value="Sindh">Sindh</option>
                                <option value="Gilgit Baltistan">Gilgit Baltistan</option>
                                <option value="Azad Jammu and Kashmir">Azad Jammu and Kashmir</option>
                              </select>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Bank Account Detail
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group  ">
                        <label>Account Title<span className="color-amber">*</span></label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${accTitleError ? 'required-border' : ''
                              }`}
                            value={accTitle}
                            onChange={(e) => {
                              if (e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value == '') {
                                setAccTitle(e.target.value);
                                setAccTitleError('');
                              }
                            }}
                          />
                          {/* {accTitleError ? <p className="error-labels error-message2">{accTitleError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Account No<span className="color-amber">*</span></label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${accNoError ? 'required-border' : ''
                              }`}
                            value={accNo}
                            onChange={(e) => {
                              if (e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value == '') {
                                setAccNo(e.target.value);
                                setAccNoError('');
                              }
                            }}
                          />
                          {/* {accNoError ? <p className="error-labels error-message">{accNoError}</p> : ''} */}
                        </div>
                      </div>

                      <Row>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>Bank Name<span className="color-amber">*</span></label>
                            <div  >
                              <select
                                className={`form-control  w-100 ${bankNameError ? 'required-border' : ''
                                  }`}
                                value={bankname}
                                onChange={(e) => {
                                  setBankNameError('');
                                  setBankName(e.target.value);
                                  getBranchByName(e.target.value);
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Bank
                                </option>
                                {renderBankNameDropdown()}
                                <option value="Other">Other</option>
                              </select>
                              {/* {bankNameError ? <p className="error-labels error-message2">{bankNameError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>Branch Name<span className="color-amber">*</span></label>

                            <input type="text" className={`form-control  w-100 ${bankNameError ? 'required-border' : ''
                              }`}
                              value={branchName}
                              onChange={(e) => {
                                setBankNameError('');
                                setBranchName(e.target.value);
                              }}
                            />

                            {/* <ReactTooltip	
                      textColor="white"	
                      backgroundColor="black"	
                      multiline={true}	
                      effect="float"	
                    />	
                    <div	
                      className=" "	
                      id="x"	
                      data-tip={`First Select Bank`}	
                    >	
                      {branchLoading ? (	
                         <div className="form-group">	
                          <div className="ml-2 form-control" >Branch Loading	
                           <i className="fa fa-spinner fa-spin fa-1x ml-3" aria-hidden="true"></i> 	
                          </div> 	
                         </div>	
                      ) : (	
                        <select	
                          className={`form-control  w-100 ${	
                            branchNameError ? 'required-border' : ''	
                          }`}	
                          value={branchName}	
                          onChange={(e) => {	
                            setBankNameError('');	
                            setBranchName(e.target.value);	
                          }}	
                        >	
                          <option value="" defaultChecked hidden>	
                            {branchCheckFlag	
                              ? 'No Branch Found'	
                              : 'Select Branch Name'}	
                          </option>	
                          {renderBranchsDropdown()}	
                        </select>	
                      )}	
                     </div>	 */}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>


                {/*add new cards  */}
                {type === 'Individual' && (
                  <Col md="4">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">
                          Guardian Details
                        </CardTitle>
                      </CardHeader>
                      <CardBody>

                        <div className="form-group">
                          <label>Guardian  Name   </label>
                          <div  >
                            <input
                              type="text"
                              className={`form-control  w-100 ${nameError ? 'required-border' : ''
                                }`}
                              value={guardian_name}
                              onChange={(e) => {
                                setGuardian_name(e.target.value);
                                // setNameError('');		
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Guardian Relation   </label>
                          <div  >
                            <input
                              type="text"
                              className={`form-control  w-100 ${nameError ? 'required-border' : ''
                                }`}
                              value={guardian_relation}
                              onChange={(e) => {
                                setGuardian_relation(e.target.value);
                                // setNameError('');		
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Guardian CNIC   </label>
                          <div  >
                            {/* <input		
                        type="number"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={guardian_cnic}		
                        onChange={(e) => {		
                          setGuardian_cnic(e.target.value);		
                          // setNameError('');		
                        }}		
                      />		 	 */}
                            <InputMask
                              className={`form-control  w-100 ${nameError ? 'required-border' : ''
                                }`}
                              mask="99999-9999999-9"
                              value={guardian_cnic}
                              onChange={(e) => {
                                setGuardian_cnic(e.target.value);
                              }}></InputMask>

                          </div>
                        </div>

                      </CardBody>
                    </Card>
                  </Col>
                )}
                {type === 'Individual' && (
                  <Col md="4">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">
                          Nominee Details
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <div className="form-group">
                          <label>Nominee   Name   </label>
                          <div  >
                            <input
                              type="text"
                              className={`form-control  w-100 ${nameError ? 'required-border' : ''
                                }`}
                              value={nominee_name}
                              onChange={(e) => {
                                setNomineeName(e.target.value);
                                setNameError('');
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Nominee  Relation   </label>
                          <div  >
                            <input
                              type="text"
                              className={`form-control  w-100 ${nameError ? 'required-border' : ''
                                }`}
                              value={nominee_relation}
                              onChange={(e) => {
                                setNomineeRelation(e.target.value);
                                // setNameError('');		
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Nominee  CNIC   </label>
                          <div  >
                            {/* <input		
                        type="number"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={nominee_cnic}		
                        onChange={(e) => {		
                          setNomineeCnic(e.target.value);		
                          // setNameError('');		
                        }}		
                      />		 	 */}

                            <InputMask
                              className={`form-control  w-100 ${nameError ? 'required-border' : ''
                                }`}
                              mask="99999-9999999-9"
                              value={nominee_cnic}
                              onChange={(e) => {
                                setNomineeCnic(e.target.value);
                              }}></InputMask>

                          </div>
                        </div>

                      </CardBody>
                    </Card>
                  </Col>
                )}

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Joint Holder 1
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group">
                        <label> Name   </label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            value={joint_h_1_name}
                            onChange={(e) => {
                              setJointHolderOneName(e.target.value);
                              // setNameError('');		
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Father/Spouse Name   </label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            value={joint_h_1_father_spouse}
                            onChange={(e) => {
                              setJointHolderOneFather(e.target.value);
                              setNameError('');
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>  CNIC   </label>
                        <div  >
                          {/* <input		
                        type="number"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={joint_h_1_cnic}		
                        onChange={(e) => {		
                          setJointHolderOneCnic(e.target.value);		
                          // setNameError('');		
                        }}		
                      />		 	 */}
                          <InputMask
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            mask="99999-9999999-9"
                            value={joint_h_1_cnic}
                            onChange={(e) => {
                              setJointHolderOneCnic(e.target.value);
                            }}></InputMask>
                        </div>
                      </div>

                      <div className="form-group">

                        <label>Tax Status</label>
                        <select
                          className="form-control"
                          value={joint_h_1_taxStatus}
                          onChange={(e) => {
                            setJointHolderOneTaxStatus(e.target.value)
                          }}
                        >
                          <option value="" hidden> Select Tax Type</option>
                          <option value="Filer">Filer</option>
                          <option value="Non-Filer">Non-Filer</option>
                        </select>
                      </div>

                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Joint Holder 2
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group">
                        <label>Name   </label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            value={joint_h_2_name}
                            onChange={(e) => {
                              setJointHolderTwoName(e.target.value);
                              // setNameError('');		
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Father/Spouse Name   </label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            value={joint_h_2_father_spouse}
                            onChange={(e) => {
                              setJointHolderTwoFather(e.target.value);
                              // setNameError('');		
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label> CNIC   </label>
                        <div  >
                          {/* <input		
                        type="number"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={joint_h_2_cnic}		
                        onChange={(e) => {		
                          setJointHolderTwoCnic(e.target.value);		
                          // setNameError('');		
                        }}		
                      />		 	 */}
                          <InputMask
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            mask="99999-9999999-9"
                            value={joint_h_2_cnic}
                            onChange={(e) => {
                              setJointHolderTwoCnic(e.target.value);
                            }}></InputMask>
                        </div>
                      </div>

                      <div className="form-group">

                        <label>Tax Status</label>
                        <select
                          className="form-control"
                          value={joint_h_2_taxStatus}
                          onChange={(e) => {
                            setJointHolderTwoTaxStatus(e.target.value)
                          }}>
                          <option value="" hidden> Select Tax Type</option>
                          <option value="Filer">Filer</option>
                          <option value="Non-Filer">Non-Filer</option>
                        </select>
                      </div>

                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Joint Holder 3
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group">
                        <label>Name   </label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            value={joint_h_3_name}
                            onChange={(e) => {
                              setJointHolderThreeName(e.target.value);
                              setNameError('');
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Father/Spouse Name   </label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            value={joint_h_3_father_spouse}
                            onChange={(e) => {
                              setJointHolderThreeFather(e.target.value);
                              // setNameError('');		
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>  CNIC   </label>
                        <div  >
                          {/* <input		
                        type="number"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={joint_h_3_cnic}		
                        onChange={(e) => {		
                          setJointHolderThreeCnic(e.target.value);		
                          // setNameError('');		
                        }}		
                      />		 	 */}

                          <InputMask
                            className={`form-control  w-100 ${nameError ? 'required-border' : ''
                              }`}
                            mask="99999-9999999-9"
                            value={joint_h_3_cnic}
                            onChange={(e) => {
                              setJointHolderThreeCnic(e.target.value);
                            }}></InputMask>
                        </div>
                      </div>

                      <div className="form-group">

                        <label>Tax Status</label>
                        <select
                          className="form-control"
                          value={joint_h_3_taxStatus}
                          onChange={(e) => {
                            setJointHolderThreeTaxStatus(e.target.value)
                          }}>
                          <option value="" hidden> Select Tax Type</option>
                          <option value="Filer">Filer</option>
                          <option value="Non-Filer">Non-Filer</option>
                        </select>
                      </div>

                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        CDC
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group">
                        <label>Investment Account Service</label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={cdc_IAS}
                            onChange={(e) => {
                              setCDCIAS(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label> CDC Subaccount </label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={cdc_subAccount}
                            onChange={(e) => {
                              setCDCSubAccount(e.target.value);
                            }}
                          />

                        </div>
                      </div>
                      <div className="form-group">
                        <label>CDC participant ID</label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={cdc_participantID}
                            onChange={(e) => {
                              setCDCParticipantID(e.target.value);
                            }}
                          />

                        </div>
                      </div>


                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Funds Portfolio
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      {totalFunds.length > 0 && (
                        <div className="input-size-pos w-100 ml-auto mb-3 mx-3">
                          <table className="table ">
                            <thead>
                              <tr>
                                <th >Fund Name</th>
                                <th >Fund Unit</th>
                              </tr>
                            </thead>
                            <tbody>{renderFundDataInTable()}</tbody>
                          </table>
                        </div>
                      )}


                    </CardBody>
                  </Card>
                </Col>



              </Row>

              <button
                className="btn btn-primary mr-2"
                onClick={addClients}
                disabled={Boolean(Loading)}
              >
                {Loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Update</span>
                )}
              </button>
              <button
                className="btn btn-default"
                onClick={() => {
                  history.replace('/admin/unit-holders');
                }}
              >
                Cancel
              </button>
              {renderModalAddCity()}

            </Container>

          </Col>
        </Row>
      </div>
    </>
  );
};
export default EditClients;	
