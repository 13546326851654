import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

const getAccounts = async (email: string) => {
  const url = `${Config.baseUrl}/account?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAccounts(email)
      } else {
        return result
      }
    }else {
      throw err
    }
   
  }
};
const getAccountByAmc = async (email: string, amcCode: string) => {
  const url = `${Config.baseUrl}/account/by-amc-code?email=${email}&amc_code=${amcCode}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAccountByAmc(email, amcCode)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const getAccountByFund = async (email: string, fund_name: string) => {
  const url = `${Config.baseUrl}/account/by-fund?email=${email}&fund_code=${fund_name}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAccountByFund(email, fund_name)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }

  }
};

const getActiveAccountByFund = async (email: string, fund_name: string) => {
  const token = sessionStorage.getItem('token') || '';
  const url = `${Config.baseUrl}/account/active-by-fund?email=${email}&fund_code=${fund_name}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getActiveAccountByFund(email,fund_name)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
      }
}
};

export { getAccounts, getAccountByFund, getAccountByAmc, getActiveAccountByFund };
