import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import MultiSelect from 'react-multi-select-component';
import moment from 'moment';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { BsTable } from 'react-icons/bs';
import { getFunds } from './../../stores/services/funds.service';
import html2pdf from 'html2pdf.js';
import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { getAccountByFund } from '../../stores/services/account.service';
import { getBankStatement, getBankStatementOpeningBalance } from '../../stores/services/report.service';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from './report-header';
import { isDateValid, isNumber } from '../../utils/customFunction';

const BankStatement = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const amc_name = sessionStorage.getItem('amc_name') || '';
  let date = new Date();
  const [bankStatement, setBankStatement] = useState<any>([]);
  const [bankStatementDataCount, SetBankStatementDataCount] = useState(0);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [allFundAccounts, setAllFundsAccounts] = useState<any>([]);
  const [fund, setFund] = useState('');
  const [type, setType] = useState('');
  const [fundAccount, setFundAccount] = useState('');
  const [accountTitle, setAccountTitle] = useState('');
  const [openingBalance, setOpeningBalance] = useState('');
  const [closingBalance, setClosingBalance] = useState('');
  const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [resAmc, setResAmc] = useState('');
  const [resFund, setResFund] = useState('');
  const [resType, setResType] = useState('');
  const [resFundAccount, setResFundAccount] = useState('');
  const [resAccountTitle, setResAccountTitle] = useState('');
  const [resFromDate, setResFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [resToDate, setResToDate] = useState(moment(date).format('YYYY-MM-DD'));

  useEffect(() => {
    const getAllAmcFunds = async () => {
      try {
        allFundAccounts.length = 0;
        setAllFundsAccounts(allFundAccounts);
        allFunds.length = 0;
        setAllFunds(allFunds);
        const response = await getFundByAmc(email, amc_code);
        setAllFunds(response.data.data);
      } catch (error) {

      }
    }
    getAllAmcFunds();
  }, [])

  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const description = (value) => {
    let array = value.split('-');
    if (array[1] != undefined) {
      let txnName = CapTxnType(array[1]);
      return value.replace(array[1], txnName)
    } else {
      return value
    }
  }

  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index: number) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
        </option>
      );
    });
  }

  const getFundAccounts = async (fund_code) => {
    try {
      allFundAccounts.length = 0;
      setAllFundsAccounts(allFundAccounts);
      const accResponse = await getAccountByFund(email, fund_code);
      setAllFundsAccounts(accResponse.data.data);
    } catch (error) {

    }
  }

  const renderFundAccountsDropDown = () => {
    return allFundAccounts.map((item: any, index: number) => {
      return (
        <option key={index} value={item.account_no}>
          {item.bank_name} ({item.account_title})
        </option>
      );
    });
  }

  //code for search data for Reports

  const searchData = async () => {
    try {
      bankStatement.length = 0;
      setBankStatement(bankStatement);
      setOpeningBalance('0');
      setClosingBalance('0');
      setLoading(true);
      // let fromMonths = new Date(fromDate);
      // let toMonths = new Date(toDate);
      // var premonths;
      // premonths = (toMonths.getFullYear() - fromMonths.getFullYear()) * 12;
      // premonths -= fromMonths.getMonth();
      // premonths += toMonths.getMonth();
      // if(premonths<6){
      // const response = await getBankStatement(email, fundAccount, fromDate,toDate,type);
      const response = await getBankStatement(email, fundAccount, fromDate, toDate, 'Realized');
      const openingResponse = await getBankStatementOpeningBalance(email, fundAccount, fromDate)
      let array = Object.entries(response.data.data);
      let array1 = Object.entries(openingResponse.data.data);
      let data: any = [];
      let balance = 0;
      if (array1.length > 0) {
        let temp = [] as any;
        temp = array1[0][1];

        // if (type == "Transaction" || type == "") {
        //   setOpeningBalance(temp.Record.processed_opening_balance == '0' ? '0' : temp.Record.processed_opening_balance);
        //   balance = parseFloat(temp.Record.processed_opening_balance == '0' ? '0' : temp.Record.processed_opening_balance);
        // } else 
        {
          setOpeningBalance('0');
          balance = parseFloat('0');
          setOpeningBalance(temp.Record.realized_opening_balance == '0' ? '0' : temp.Record.realized_opening_balance);
          balance = parseFloat(temp.Record.realized_opening_balance == '0' ? '0' : temp.Record.realized_opening_balance);
        }
      }
      setResAmc(amc_name);
      setResFund(fund);
      setResType(type);
      setResFundAccount(fundAccount);
      setResAccountTitle(accountTitle);
      setResFromDate(fromDate);
      setResToDate(toDate);
      array.forEach(([key, value]) => {

        data = value;
        let record = [];
        record['Record'] = data.Record;
        record['Record']['ledger_id'] = data.Key;
        balance += parseFloat(data.Record.debit_amount) - isNumber(parseFloat(data.Record.credit_amount.toString()));
        record['Record']['balance'] = balance.toFixed(2);
        bankStatement.push(record);
      });
      setClosingBalance(balance.toFixed(2));
      SetBankStatementDataCount(bankStatement.length);
      setLoading(false);
      // }else{
      //   toast.error('You cannot generate report upto 6 months')
      //   setLoading(false);
      // }
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
      setLoading(false);
    }
  }


  const numberWithCommas = (amount: any) => {
    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Bank Statement</h4>
                  </div>
                  <div className="card-body">
                    <Row>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setFund(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text)
                              getFundAccounts(e.target.value);
                            }}>
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund Account</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setFundAccount(e.target.value);
                              var AccountTitle = e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                              setAccountTitle(AccountTitle.split('(')[1].replace(')', ''));
                            }}>
                            <option value="" defaultChecked hidden>
                              Select Fund Account
                            </option>
                            {renderFundAccountsDropDown()}
                          </select>
                        </div>
                      </Col>

                      {/* <Col md="2">
                <div className="form-group">
                  <label>Select Type</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                        setType(e.target.value);
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Type
                    </option>
                    <option value="Transaction">Transaction</option>
                    <option value="Realized">Realized</option>
                  </select>
                </div>
              </Col> */}

                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date From</label>
                          <input
                            type="date"
                            defaultValue={fromDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format('YYYY-MM-DD');
                              setFromDate(date);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date To</label>
                          <input
                            type="date"
                            defaultValue={toDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format('YYYY-MM-DD');
                              setToDate(date);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className=" ">
                          {/* here Search Button */}
                          <button
                            className="btn btn-primary mt-4"
                            onClick={() => {
                              searchData();
                            }}
                            disabled={Boolean(loading)}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Search</span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: 'white', color: 'black' }}
                    >
                      <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} fileName={"Bank-Statement-" + date + ".pdf"} ref={pdfExportComponent}>
                        {bankStatement.length > 0 ? (
                          <>
                            <ReportHeader title="Bank Statement" />
                            {/* <div className="title-row d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">Bank Statement</h3>
                  </div> */}
                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  AMC:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resAmc}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Fund:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resFund}</span>
                              </Col>
                            </Row>

                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Account Title:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resAccountTitle}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Account No:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{resFundAccount}</span>
                              </Col>
                            </Row>
                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  From Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
                              </Col>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  To Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
                              </Col>
                            </Row>
                            <Row noGutters style={{ marginLeft: '80px' }}>
                              <Col md='2'>
                                <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                  Report Date:
                                </span>
                              </Col>
                              <Col md='4'>
                                <span className="text-wrap">{moment(date).format('DD-MM-YYYY')}</span>
                              </Col>
                            </Row>
                            {/* <Row>
                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      AMC:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{resAmc}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Fund:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap" >{resFund}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Account Title:
                                    </span>
                                  </Col>
                                  <Col md="6">

                                    <span className="text-nowrap">{resAccountTitle}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Account No:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{resFundAccount}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      From Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>To Date:</span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                            </Row> */}

                            <div
                              style={{
                                overflowX: 'auto',
                                overflowY: 'hidden',
                              }}
                            >
                              <div className="float-right"><b>Opening Balance</b> : {numberWithCommas(openingBalance)}</div>
                              <table className="report" style={{ color: 'black' }}>
                                <thead style={{ alignSelf: 'center' }}>
                                  <tr>
                                    <th>Date</th>
                                    <th>Transaction ID</th>
                                    <th>Description</th>
                                    <th>Debit Amount</th>
                                    <th>Credit Amount</th>
                                    <th>Balance</th>
                                  </tr>
                                </thead>
                                <tbody className="report-table-body">
                                  {
                                    // type == "Transaction" || type == "" ? (
                                    //   bankStatement.map((item: any, index: number) => {
                                    //     return (
                                    //       <>
                                    //         <tr key={index}>
                                    //           <td>{isDateValid(moment(item.Record.realized_date).format('DD-MM-YYYY'))}</td>
                                    //           <td>{item.Record.txn_id.replace('TXN_', '')}</td>
                                    //           <td>{description(item.Record.description)}</td>
                                    //           <td className="text-right">{isNumber(numberWithCommas(item.Record.debit_amount.toString()))}</td>
                                    //           <td className="text-right">{isNumber(numberWithCommas(item.Record.credit_amount.toString()))}</td>
                                    //           <td className="text-right">{isNumber(numberWithCommas(item.Record.balance.toString()))}</td>
                                    //         </tr>
                                    //       </>
                                    //     );
                                    //   })

                                    // ) : (
                                    bankStatement.map((item: any, index: number) => {
                                      return (
                                        <>
                                          <tr key={index}>
                                            <td>{isDateValid(moment(item.Record.realized_date).format('DD-MM-YYYY'))}</td>
                                            <td>{item.Record.txn_id.replace('TXN_', '')}</td>
                                            <td>{description(item.Record.description)}</td>
                                            <td className="text-right">{numberWithCommas(item.Record.debit_amount.toString())}</td>
                                            <td className="text-right">{numberWithCommas(item.Record.credit_amount.toString())}</td>
                                            <td className="text-right">{numberWithCommas(item.Record.balance.toString())}</td>
                                          </tr>
                                        </>
                                      );
                                    })
                                    // )
                                  }
                                </tbody>
                              </table>
                            </div>
                            <div className="float-right"><b>Closing Balance</b> : {numberWithCommas(closingBalance)}</div>
                          </>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center empty-text">No record found</p>
                          </div>
                        )}
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        // printReport();
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                    >
                      Print
                    </button>

                    {/* <button
              className="btn btn-info mt-3"
              onClick={() => {
                var printContents = document.getElementById("printDiv")!.innerHTML;
                var originalContents = document.body.innerHTML;
           
                document.body.innerHTML = printContents;
           
                window.print();
           
                document.body.innerHTML = originalContents;

              }}
            >
              Print
            </button> */}
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BankStatement;
